//Atributos
$(document).on('click','.nuevoAtributoModal', function(){
    $("#_method").val("POST");
    $("#formAtributo").attr('action',"atributos");
    $("#fichaAtributoLabel").html("Creación de nuevo atributo");
    $("#btnDeleteAtributo").css("display","none");
    $("#status-modal").addClass("d-none");
    $("#tituloValores").empty();
    $("#contenedorValores").empty();
    $(".campo").val("");
    $("#type").empty();
    $("#grupoatributo_id").empty();
    $("#type").prop("disabled",false);
    $("#code").attr('disabled',false);


    $.ajax({
        url: "grupoatributos/aux",
        type: "GET",
        dataType: "json",
        success: function(data2){
            var optionsGroup = "<option value=''>Grupo</option>";
            $.each(data2,function(index3,value3){
                optionsGroup += "<option value='"+value3['id']+"'>"+value3['name']+"</option>";
            });
            $("#grupoatributo_id").append(optionsGroup);
        }
    });
        
    $("#type").append("<option value=''>Tipo</option><option value='select'>Select</option><option value='multiselect'>Multiselect</option><option value='text'>Texto</option><option value='boolean'>Si/No</option><option value='textarea'>Texto largo</option>");

});
$(document).on("click",".editaValor",function(){
    var id = this.id.substr(6,10);
    var nameValor = $("#nameValor-"+id).val();
    $.ajax({
        url: "valoresatributos/"+id,
        type: "PUT",
        data:{
            _token: $("#token-"+id).val(),
            _method:'PUT',
            name:nameValor
        },
        success: function(data){
            $("#status-modal p").empty();
            $("#status-modal p").append("Valor editado con éxito");
            $("#status-modal").removeClass("d-none");
        }
    }); 
});
$(document).on('click','.nuevoGrupoatributoModal', function(){
    $("#formGrupoatributo #_method").val("POST");
    $("#formGrupoatributo").attr('action',"grupoatributos");
    $("#fichaGrupoatributoLabel").html("Creación de nuevo  grupo de atributo");
});

//Tarifas
//Edicion
$(document).on('click','.abreTarifaModal', function(){
    var id = $(this).parent().attr('id').substr(7,25);
    $("#_method").val("PUT");
    $("#formTarifa").attr('action',"tarifas/"+id);
    $("#btnDeleteTarifa").css("display","block");
    $("#btnDeleteTarifa").attr('onclick',"document.getElementById('delete-"+id+"').submit()");
    
    
    $(".campo").val("");
    $("#reglas-tarifa").empty();
    $.ajax({
        url: "tarifas/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#fichaTarifaLabel").html("Edición de tarifa "+data['name']);
            $.each(data,function(index,value){
                if(index == "reglas"){
                    $("#reglas-tarifa").append(`
                        <div class="cabecera-items" style='position:sticky; top:0; background-color:#fff'> 
                            <div class="cabecera-item-regla-orden">
                                <p class='m-auto text-center mb-1 fw-bolder'>Orden</p>
                            </div>
                            <div class="cabecera-item-regla-base"'>
                                <p class='m-auto text-center mb-1 fw-bolder'>Base</p>
                            </div>
                            <div class="cabecera-item-regla-tipo">
                                <p class='m-auto text-center mb-1 fw-bolder'>Tipo</p>
                            </div>
                            <div class="cabecera-item-regla-cantidad">
                                <p class='m-auto text-center mb-1 fw-bolder'>Cant</p>
                            </div>
                            <div class="cabecera-item-regla-borrar"></div>
                        </div>
                    `);
                    $.each(value, function(index2,value2){
                        $("#reglas-tarifa").append(`
                            <div class="registro-items" id="regla-`+value2['id']+`" style='position:sticky; top:0; background-color:#fff'> 
                                <div class="registro-item-regla-orden">
                                    <input type="text" name="input_item_orden[]" class='form-control m-auto text-center mb-1 input-item-orden'  readonly value='`+value2['orden']+`'>
                                </div>
                                <div class="registro-item-regla-base">
                                    <select name="input_item_base[]" id="baseregla-`+value2['id']+`" class='form-control m-auto text-center mb-1 input-item-base'  value='`+value2['base']+`'>
                                        <option value="puc">Precio último de coste</option>
                                        <option value="pmc">Precio medio de coste</option>
                                        <option value="pvp">Precio venta público</option>
                                        <option value="pvl">Precio venta laboratorio</option>
                                        <option value="pva">Precio venta almacenista</option>
                                    </select>
                                </div>
                                <div class="registro-item-regla-tipo">
                                    <select name="input_item_tipo[]" id="tiporegla-`+value2['id']+`" class='form-control m-auto text-center mb-1 input-item-tipo'  value='`+value2['tipo']+`'>
                                        <option value="%">%</option>
                                        <option value="€">€</option>
                                    </select>
                                </div>
                                <div class="registro-item-regla-cantidad">
                                    <input name="input_item_cantidad[]" class="form-control m-auto text-center input-item-cantidad" type='number' step='0.01' value='`+value2['cantidad']+`' >
                                </div>
                                <div class="registro-item-regla-borrar">
                                    <a title='Borrar' class='borrar-regla' href='#'><i class="bi bi-trash3-fill"></i></a>
                                </div>
                            </div>
                        `);
                        if(value2['orden'] != 1){
                            $("#baseregla-"+value2['id']).append(`<option value="acumulado">Acumulado</option>`);
                        }
                        $("#baseregla-"+value2['id']).val(value2['base']).change();
                        $("#tiporegla-"+value2['id']).val(value2['tipo']).change();
                    });
                }
                $("#"+index.toLowerCase()).val(value);
                $("#aplica").change();
            });
        }
    }); 
});
$(document).on('click','.nuevaTarifaModal', function(){
    $("#_method").val("POST");
    $("#formTarifa").attr('action',"tarifas");
    $("#fichaTarifaLabel").html("Creación de nueva tarifa");
    $("#btnDeleteTarifa").css("display","none");
    
    $(".campo").val("");
    $("#reglas-tarifa").empty();
    $("#reglas-tarifa").append(`
        <div class="cabecera-items" style='position:sticky; top:0; background-color:#fff'> 
            <div class="cabecera-item-regla-orden">
                <p class='m-auto text-center mb-1 fw-bolder'>Orden</p>
            </div>
            <div class="cabecera-item-regla-base"'>
                <p class='m-auto text-center mb-1 fw-bolder'>Base</p>
            </div>
            <div class="cabecera-item-regla-tipo">
                <p class='m-auto text-center mb-1 fw-bolder'>Tipo</p>
            </div>
            <div class="cabecera-item-regla-cantidad">
                <p class='m-auto text-center mb-1 fw-bolder'>Cant</p>
            </div>
            <div class="cabecera-item-regla-borrar"></div>
        </div>
    `);
    $("#aplica").val("price").change();
});
//Reglas
$(document).on('click','.addRegla',function(){
    var orden = 0;
    $.each(($(".input-item-orden")),function(index,value){
        orden = $(this).val();
    });
    orden = parseFloat(orden) + 1;
    if(orden == 1){
        $("#reglas-tarifa").append(`
            <div class="registro-items" style='position:sticky; top:0; background-color:#fff'> 
                <div class="registro-item-regla-orden">
                    <input type="text" name="input_item_orden[]" class='form-control m-auto text-center mb-1 input-item-orden' readonly  value='`+orden+`'>
                </div>
                <div class="registro-item-regla-base">
                    <select name="input_item_base[]" class='form-control m-auto text-center mb-1 input-item-base'  value=''>
                        <option value="puc">Precio último de coste</option>
                        <option value="pmc">Precio medio de coste</option>
                        <option value="pvp">Precio venta público</option>
                        <option value="pvl">Precio venta laboratorio</option>
                        <option value="pva">Precio venta almacenista</option>
                    </select>
                </div>
                <div class="registro-item-regla-tipo">
                    <select name="input_item_tipo[]" class='form-control m-auto text-center mb-1 input-item-tipo'  value=''>
                        <option value="%">%</option>
                        <option value="€">€</option>
                    </select>
                </div>
                <div class="registro-item-regla-cantidad">
                    <input name="input_item_cantidad[]" class="form-control m-auto text-center input-item-cantidad" type='number' step='0.01' value='0.00' >
                </div>
                <div class="registro-item-regla-borrar">
                    <a title='Borrar' class='borrar-regla' href='#'><i class="bi bi-trash3-fill"></i></a>
                </div>
            </div>
        `);
    }else{
        $("#reglas-tarifa").append(`
            <div class="registro-items" style='position:sticky; top:0; background-color:#fff'> 
                <div class="registro-item-regla-orden">
                    <input type="text" name="input_item_orden[]" class='form-control m-auto text-center mb-1 input-item-orden' readonly  value='`+orden+`'>
                </div>
                <div class="registro-item-regla-base">
                    <select name="input_item_base[]" class='form-control m-auto text-center mb-1 input-item-base'  value=''>
                        <option value="puc">Precio último de coste</option>
                        <option value="pmc">Precio medio de coste</option>
                        <option value="pvp">Precio venta público</option>
                        <option value="pvl">Precio venta laboratorio</option>
                        <option value="pva">Precio venta almacenista</option>
                        <option value="acumulado">Acumulado</option>
                    </select>
                </div>
                <div class="registro-item-regla-tipo">
                    <select name="input_item_tipo[]" class='form-control m-auto text-center mb-1 input-item-tipo'  value=''>
                        <option value="%">%</option>
                        <option value="€">€</option>
                    </select>
                </div>
                <div class="registro-item-regla-cantidad">
                    <input name="input_item_cantidad[]" class="form-control m-auto text-center input-item-cantidad" type='number' step='1.00' value='0.00' >
                </div>
                <div class="registro-item-regla-borrar">
                    <a title='Borrar' class='borrar-regla' href='#'><i class="bi bi-trash3-fill"></i></a>
                </div>
            </div>
        `);
    }
});
$(document).on('click','.borrar-regla',function(){
    $(this).parent().parent().remove();
});
$(document).on('change','.input-item-tipo',function(){
    if(this.value == "€" && $(this).parent().parent().find(".input-item-orden").val() != "1"){
        $(this).parent().parent().find(".input-item-base").val("acumulado").change().find(':not(:selected)').attr("disabled",true);
    }else{
        $(this).parent().parent().find(".input-item-base").find(':not(:selected)').attr("disabled",false);
    }
});
//Asignacion de tarifas a productos
function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
function consultaProductos(url,page){
    $.ajax({
        url: url,
        type: "GET",
        dataType: "json",
        success: function(data){
            if(page == 1){
                $("#productos-lista").empty();
                $("#productos-lista").append(`
                    <div class="cabecera-lista"> 
                        <div class="cabecera-lista-orden" style='width:5%'>
                            <p class='m-auto text-center mb-1 fw-bolder'>#</p>
                        </div>
                        <div class="cabecera-lista-sku" style='width:10%'>
                            <p class='m-auto text-center mb-1 fw-bolder'>SKU</p>
                        </div>
                        <div class="cabecera-lista-nombre" style='width:27%'>
                            <p class='m-auto text-center mb-1 fw-bolder'>Nombre</p>
                        </div>
                        <div class="cabecera-lista-categorias" style='width:18%'>
                            <p class='m-auto text-center mb-1 fw-bolder'>Categorias</p>
                        </div>
                        <div class="cabecera-lista-tarifaprecio" style='width:10%'>
                            <p class='m-auto text-center mb-1 fw-bolder'>Tarifa precio</p>
                        </div>
                        <div class="cabecera-lista-precio" style='width:10%'>
                            <p class='m-auto text-center mb-1 fw-bolder'>Precio</p>
                        </div>
                        <div class="cabecera-lista-tarifaespecial" style='width:10%'>
                            <p class='m-auto text-center mb-1 fw-bolder'>Tarifa Precio Especial</p>
                        </div>
                        <div class="cabecera-lista-especial" style='width:10%'>
                            <p class='m-auto text-center mb-1 fw-bolder'>Precio Especial</p>
                        </div>
                        <div class="cabecera-lista-stock" style='width:10%'>
                            <p class='m-auto text-center mb-1 fw-bolder'>Stock</p>
                        </div>
                        <div class="d-flex flex-column justify-content-center" style='width:10%'>
                            <p class='text-center ms-2 me-2 mb-1 fw-bolder'>Añadir/Quitar</p>
                            <div class='text-center'>
                                <a href='#' title='Añadir todos los productos cargados' id='addalltotarifa'><i class='bi bi-plus-circle-fill' style='color:green'></i></a>
                                <a href='#' title='Quitar todos los productos cargados' id='quitalltotarifa'><i class='bi bi-dash-circle-fill' style='color:red'></i></a>
                            </div>
                        </div>
                    </div>
                `);
            }
            $.each(data['data'],function(index,value){
                var categorias = "";
                $.each(value['assigned_categorias'],function(index2,value2){
                    if(index2 == 0){
                        categorias += value2['name'];
                    }else{
                        categorias += ", " + value2['name'];
                    }
                });
                var addquit             = "<a href='#' class='addproducttotarifa' id='addproducttotarifa-"+value['id']+"'><i class='bi bi-plus-circle-fill' style='color:green'></i></a>";
                var sombreado           = "";
                var tarifa_pvp          = "Sin tarifa";
                var tarifa_pvp_especial = "Sin tarifa";
                $.each(value['assigned_tarifas'],function(index2,value2){
                    if($("#id-tarifa").html() == value2['id']){
                        addquit   = "<a href='#' class='quitproducttotarifa' id='quitproducttotarifa-"+value['id']+"'><i class='bi bi-dash-circle-fill' style='color:red'></i></a>";
                        sombreado = "style='background-color:#E8F8F5'";
                    }
                    if(value2['pivot']['normal'] == 1){
                        tarifa_pvp = value2['name'];
                    }
                    if(value2['pivot']['special'] == 1){
                        tarifa_pvp_especial = value2['name'];
                    }
                });

                var html = `
                    <div class="registro-lista" `+sombreado+`> 
                        <div class='d-flex flex-column w-100'>
                            <div class='d-flex flex-row align-items-center w-100'>
                                <div class="registro-lista-orden" style='width:5%'>
                                    <p class='m-auto text-center mb-1 capitalise'>`+idRegistro+`</p>
                                </div>
                                <div class="registro-lista-sku" style='width:10%'>
                                    <p class='m-auto text-center mb-1 capitalise'>`+value['sku']+`</p>
                                </div>
                                <div class="registro-lista-nombre" style='width:27%'>
                                    <p class='m-auto text-center mb-1 capitalise'>`+value['name']+`</p>
                                </div>
                                <div class="registro-lista-categorias" style='width:18%'>
                                    <p class='m-auto text-center mb-1 capitalise'>`+categorias+`</p>
                                </div>
                                <div class="registro-lista-tarifaprecio" style='width:10%'>
                                    <p class='m-auto text-center mb-1'>`+tarifa_pvp+`</p>
                                </div>
                                <div class="registro-lista-precio" style='width:10%'>
                                    <p class='m-auto text-center mb-1'>`+value['price']+`€</p>
                                </div>
                                <div class="registro-lista-tarifaespecial" style='width:10%'>
                                    <p class='m-auto text-center mb-1'>`+tarifa_pvp_especial+`</p>
                                </div>
                                <div class="registro-lista-especial" style='width:10%'>
                                    <p class='m-auto text-center mb-1'>`+value['special_price']+`€</p>
                                </div>
                                <div class="registro-lista-stock" style='width:10%'>
                                    <p class='m-auto text-center mb-1'>`+value['stock']+`ud</p>
                                </div>
                                <div class="text-center" style='width:10%'>`+
                                    addquit
                                +`</div>
                            </div>
                        </div>
                    </div>
                `;  
                $("#productos-lista").append(html);
                idRegistro++;
            });
            $("#resultado").html(data['to']+" registros cargados de un total de "+data['total']+" registros encontrados.<i title='Cargar todos los productos encontrados' class='bi bi-arrow-clockwise carga-registros-completos ms-2'></i>");
            last_page = data['last_page'];
        }
    });
}
$(document).on('click','.asignacion-tarifa',function(){
    $("#listaProductosLabel").empty();    
    $("#filtro-box-productos").val("");
    $("#listaProductosLabel").append("Lista de productos<label id='id-tarifa' hidden>"+this.id.substr(18,20)+"</label>");
    idRegistro = 1;
    consultaProductos("productos/aux?paginate=50",1);
});
$(document).on('click','.addproducttotarifa',function(){
    var id_producto = this.id.substr(19,20);
    var linearegistro = $(this).parent().parent().parent().parent();
    var boton         = $(this).parent();
    $.ajax({
        type: "GET",
        url: "tarifas/asignacion/add-"+$("#id-tarifa").html()+"-"+id_producto,
        success: function(data){
            if(data['success'] == true){
                console.log(data['tarifa']['aplica']);
                linearegistro.css('background-color','#E8F8F5');
                boton.html("<a href='#' class='quitproducttotarifa' id='quitproducttotarifa-"+id_producto+"'><i class='bi bi-dash-circle-fill' style='color:red'></i></a>");
                if(data['tarifa']['aplica'] == "price"){
                    linearegistro.find(".registro-lista-tarifaprecio").children().html(data['tarifa']['name']);
                }else if(data['tarifa']['aplica'] == "special_price"){
                    linearegistro.find(".registro-lista-tarifaespecial").children().html(data['tarifa']['name']);
                }
            }
        },
      });
});
$(document).on('click','.quitproducttotarifa',function(){
    var id_producto   = this.id.substr(20,20);
    var linearegistro = $(this).parent().parent().parent().parent();
    var boton         = $(this).parent();
    $.ajax({
        type: "GET",
        url: "tarifas/asignacion/quit-"+$("#id-tarifa").html()+"-"+id_producto,
        success: function(data){
            if(data['success'] == true){
                linearegistro.css('background-color','#FFFFFF');
                boton.html("<a href='#' class='addproducttotarifa' id='addproducttotarifa-"+id_producto+"'><i class='bi bi-plus-circle-fill' style='color:green'></i></a>");
                if(data['tarifa']['aplica'] == "price"){
                    linearegistro.find(".registro-lista-tarifaprecio").children().html("Sin tarifa");
                }else if(data['tarifa']['aplica'] == "special_price"){
                    linearegistro.find(".registro-lista-tarifaespecial").children().html("Sin tarifa");
                }
            }
        },
      });
});
$(document).on('click','#addalltotarifa',function(){
    $.each($(".addproducttotarifa"),function(index,value){
        var id_producto   = value.id.substr(19,20);
        var linearegistro = $(value).parent().parent().parent().parent();
        var boton         = $(value).parent();
        console.log(id_producto);
        console.log(linearegistro);
        console.log(boton);
        $.ajax({
            type: "GET",
            url: "tarifas/asignacion/add-"+$("#id-tarifa").html()+"-"+id_producto,
            success: function(data){
                if(data['success'] == true){
                    linearegistro.css('background-color','#E8F8F5');
                    boton.html("<a href='#' title='Quitar todos los productos cargados' class='quitproducttotarifa' id='quitproducttotarifa-"+id_producto+"'><i class='bi bi-dash-circle-fill' style='color:red'></i></a>");
                }
            },
        });
    });
});
$(document).on('click','#quitalltotarifa',function(){
    $.each($(".quitproducttotarifa"),function(index,value){
        var id_producto   = value.id.substr(20,20);
        var linearegistro = $(value).parent().parent().parent().parent();
        var boton         = $(value).parent();
        $.ajax({
            type: "GET",
            url: "tarifas/asignacion/quit-"+$("#id-tarifa").html()+"-"+id_producto,
            success: function(data){
                if(data['success'] == true){
                    linearegistro.css('background-color','#FFFFFF');
                    boton.html("<a href='#' title='Añadir todos los productos cargados' class='addproducttotarifa' id='addproducttotarifa-"+id_producto+"'><i class='bi bi-plus-circle-fill' style='color:green'></i></a>");
                }
            },
        });
    });
});
//Recalculo de precios
$(document).on('click','.recalculo-precios',function(){
    var id = this.id.substr(18,20);
    $.ajax({
        type: "GET",
        url: "tarifas/recalculo/"+id,
        success: function(data){
            alert("Recalculo de precios realizado");
        },
    });
});
//Filtro y scroll de productos
let pag        = 1;
let last_page  = 1;
let idRegistro = 1;
$(document).on('keyup','#filtro-box-productos',function(){
    var value = $(this).val().toLowerCase();
    var filter = $("#filtro-select-productos").val();
    $("#productos-lista").scrollTop(0);
    pag        = 1;
    idRegistro = 1;
    consultaProductos("productos/aux?campofiltro="+filter+"&valorfiltro="+value+"&paginate=50"+"&page="+pag,pag);
});
$(document).ready(function(){
    $("#productos-lista").scroll(function(){
        var scrollHeight = $(this).height();
        var scrollPosition = $(this).scrollTop();
        if (scrollPosition / 1000 >= pag) {
            pag = pag + 1;
            var filter  = $("#filtro-select-productos").val();
            var value = $("#filtro-box-productos").val();
            if(pag <= last_page){
                consultaProductos("productos/aux?campofiltro="+filter+"&valorfiltro="+value+"&paginate=50&page="+pag,pag);
            }
        }
    });
});
$(document).on("click","#cerrarmodaladdproduct",function(){
    location.reload();
});
$(document).on("click","#cerrarmodaladdproduct2",function(){
    location.reload();
});
$(document).on("click",".check-elegir-todos",function(){
    /*
    var filter  = $("#filtro-select-productos").val();
    var value = $("#filtro-box-productos").val();
    consultaProductos("productos/aux?campofiltro="+filter+"&valorfiltro="+value+"&paginate=300000"+"&page=1",1);
    pag = last_page;
    sleep(2000);
    */
    if($(this).is(':checked')){
        $(".check-elegir").attr('checked',true);
    }else{
        $(".check-elegir").attr('checked',false);
    }
});
$(document).on("click",".carga-registros-completos",function(){
    var cuantas_paginas = 0;
    idRegistro = 1;
    var filter  = $("#filtro-select-productos").val();
    var value = $("#filtro-box-productos").val();
    //$("#productos-lista").empty();
    consultaProductos("productos/aux?campofiltro="+filter+"&valorfiltro="+value+"&paginate=300000"+"&page=1",1);
    pag = last_page;
});

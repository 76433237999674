//Tiendas
$(document).on('click','.abreTiendaModal', function(){
    formularioEnBlanco();
    var id = this.id.substr(7,25);
    console.log(id);
    $("#_method").val("PUT");
    $("#formTienda").attr('action',"tiendas/"+id);
    $("#btnDeleteTienda").css("display","block");
    $("#btnDeleteTienda").attr('onclick',"document.getElementById('delete-"+id+"').submit()");

    $("#proveedor").attr('disabled',true);

    if($("#proveedor").val() == "Magento"){
        $("#contenedor-access-token input").attr('disabled',false);
        $("#contenedor-access-token-secret input").attr('disabled',false);
    }else if($("#proveedor").val() == "Woocommerce"){
        alert("HOLA");
        $("#contenedor-access-token input").val("");
        $("#contenedor-access-token-secret input").val("");
        $("#contenedor-access-token input").attr('disabled',true);
        $("#contenedor-access-token-secret input").attr('disabled',true);
        $("#proveedor").attr('disabled',true);
    }
    
    $(".campo").val("");
    $.ajax({
        url: "tiendas/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            console.log(data);
            $("#fichaTiendaLabel").html("Edición de tienda "+data['name']);
            $.each(data,function(index,value){
                if(index == "proveedor"){
                    if(value == "Magento"){
                        $("#contenedor-url input").attr('disabled',false);
                        $("#contenedor-url").css('display','block');
                
                        $("#contenedor-consumer-key input").attr('disabled',false);
                        $("#contenedor-consumer-key").css('display','block');
                
                        $("#contenedor-consumer-secret input").attr('disabled',false);
                        $("#contenedor-consumer-secret").css('display','block');
                
                        $("#contenedor-access-token input").attr('disabled',false);
                        $("#contenedor-access-token").css('display','block');
                
                        $("#contenedor-access-token-secret input").attr('disabled',false);
                        $("#contenedor-access-token-secret").css('display','block');
                
                        $("#contenedor-tienda-google-id input").val("");
                        $("#contenedor-tienda-google-id input").attr('disabled',true);
                        $("#contenedor-tienda-google-id").css('display','none');
                
                        $("#contenedor-credenciales-google input").val("");
                        $("#contenedor-credenciales-google input").attr('disabled',true);
                        $("#contenedor-credenciales-google").css('display','none');
                    }else if(value == "Woocommerce"){
                        $("#contenedor-url input").attr('disabled',false);
                        $("#contenedor-url").css('display','block');
                
                        $("#contenedor-consumer-key input").attr('disabled',false);
                        $("#contenedor-consumer-key").css('display','block');
                
                        $("#contenedor-consumer-secret input").attr('disabled',false);
                        $("#contenedor-consumer-secret").css('display','block');
                
                        $("#contenedor-access-token input").val("");
                        $("#contenedor-access-token input").attr('disabled',true);
                        $("#contenedor-access-token").css('display','none');
                
                        $("#contenedor-access-token-secret input").val("");
                        $("#contenedor-access-token-secret input").attr('disabled',true);
                        $("#contenedor-access-token-secret").css('display','none');
                
                        $("#contenedor-tienda-google-id input").val("");
                        $("#contenedor-tienda-google-id input").attr('disabled',true);
                        $("#contenedor-tienda-google-id").css('display','none');
                
                        $("#contenedor-credenciales-google input").val("");
                        $("#contenedor-credenciales-google input").attr('disabled',true);
                        $("#contenedor-credenciales-google").css('display','none');
                
                    }else if(value == "GoogleShop"){
                        $("#contenedor-url input").val();
                        $("#contenedor-url input").attr('disabled',true);
                        $("#contenedor-url").css('display','none');

                        $("#contenedor-consumer-key input").val("");
                        $("#contenedor-consumer-key input").attr('disabled',true);
                        $("#contenedor-consumer-key").css('display','none');

                        $("#contenedor-consumer-secret input").val("");
                        $("#contenedor-consumer-secret input").attr('disabled',true);
                        $("#contenedor-consumer-secret").css('display','none');

                        $("#contenedor-access-token input").val("");
                        $("#contenedor-access-token input").attr('disabled',true);
                        $("#contenedor-access-token").css('display','none');

                        $("#contenedor-access-token-secret input").val("");
                        $("#contenedor-access-token-secret input").attr('disabled',true);
                        $("#contenedor-access-token-secret").css('display','none');

                        $("#contenedor-tienda-google-id input").attr('disabled',false);
                        $("#contenedor-tienda-google-id").css('display','block');

                        $("#contenedor-credenciales-google input").val("");
                        $("#contenedor-credenciales-google input").attr('disabled',false);
                        $("#contenedor-credenciales-google").css('display','block');
                    }
                }
                $("#"+index.toLowerCase()).val(value);
            });
        }
    }); 
});
$(document).on('click','.nuevaTiendaModal', function(){
    formularioEnBlanco();
    $("#_method").val("POST");
    $("#formTienda").attr('action',"tiendas");
    $("#fichaTiendaLabel").html("Creación de nueva tienda");
    $("#btnDeleteTienda").css("display","none");
    
    $("#proveedor").attr('disabled',false);

    $(".campo").val("");

});
$(document).on('change','#proveedor',function(){
    if($(this).val() == "Magento"){
        $("#contenedor-url input").val();
        $("#contenedor-url input").attr('disabled',false);
        $("#contenedor-url").css('display','block');

        $("#contenedor-consumer-key input").val("");
        $("#contenedor-consumer-key input").attr('disabled',false);
        $("#contenedor-consumer-key").css('display','block');

        $("#contenedor-consumer-secret input").val("");
        $("#contenedor-consumer-secret input").attr('disabled',false);
        $("#contenedor-consumer-secret").css('display','block');

        $("#contenedor-access-token input").val("");
        $("#contenedor-access-token input").attr('disabled',false);
        $("#contenedor-access-token").css('display','block');

        $("#contenedor-access-token-secret input").val("");
        $("#contenedor-access-token-secret input").attr('disabled',false);
        $("#contenedor-access-token-secret").css('display','block');

        $("#contenedor-tienda-google-id input").val("");
        $("#contenedor-tienda-google-id input").attr('disabled',true);
        $("#contenedor-tienda-google-id").css('display','none');

        $("#contenedor-credenciales-google input").val("");
        $("#contenedor-credenciales-google input").attr('disabled',true);
        $("#contenedor-credenciales-google").css('display','none');
        
    }else if($(this).val() == "Woocommerce"){
        $("#contenedor-url input").val();
        $("#contenedor-url input").attr('disabled',false);
        $("#contenedor-url").css('display','block');

        $("#contenedor-consumer-key input").val("");
        $("#contenedor-consumer-key input").attr('disabled',false);
        $("#contenedor-consumer-key").css('display','block');

        $("#contenedor-consumer-secret input").val("");
        $("#contenedor-consumer-secret input").attr('disabled',false);
        $("#contenedor-consumer-secret").css('display','block');

        $("#contenedor-access-token input").val("");
        $("#contenedor-access-token input").attr('disabled',true);
        $("#contenedor-access-token").css('display','none');

        $("#contenedor-access-token-secret input").val("");
        $("#contenedor-access-token-secret input").attr('disabled',true);
        $("#contenedor-access-token-secret").css('display','none');

        $("#contenedor-tienda-google-id input").val("");
        $("#contenedor-tienda-google-id input").attr('disabled',true);
        $("#contenedor-tienda-google-id").css('display','none');

        $("#contenedor-credenciales-google input").val("");
        $("#contenedor-credenciales-google input").attr('disabled',true);
        $("#contenedor-credenciales-google").css('display','none');

    }else if($(this).val() == "GoogleShop"){
        $("#contenedor-url input").val();
        $("#contenedor-url input").attr('disabled',true);
        $("#contenedor-url").css('display','none');

        $("#contenedor-consumer-key input").val("");
        $("#contenedor-consumer-key input").attr('disabled',true);
        $("#contenedor-consumer-key").css('display','none');

        $("#contenedor-consumer-secret input").val("");
        $("#contenedor-consumer-secret input").attr('disabled',true);
        $("#contenedor-consumer-secret").css('display','none');

        $("#contenedor-access-token input").val("");
        $("#contenedor-access-token input").attr('disabled',true);
        $("#contenedor-access-token").css('display','none');

        $("#contenedor-access-token-secret input").val("");
        $("#contenedor-access-token-secret input").attr('disabled',true);
        $("#contenedor-access-token-secret").css('display','none');

        $("#contenedor-tienda-google-id input").val("");
        $("#contenedor-tienda-google-id input").attr('disabled',false);
        $("#contenedor-tienda-google-id").css('display','block');

        $("#contenedor-credenciales-google input").val("");
        $("#contenedor-credenciales-google input").attr('disabled',false);
        $("#contenedor-credenciales-google").css('display','block');
    }
});
function formularioEnBlanco()
{
    $("#contenedor-url input").val();
    $("#contenedor-url input").attr('disabled',true);
    $("#contenedor-url").css('display','none');

    $("#contenedor-consumer-key input").val("");
    $("#contenedor-consumer-key input").attr('disabled',true);
    $("#contenedor-consumer-key").css('display','none');

    $("#contenedor-consumer-secret input").val("");
    $("#contenedor-consumer-secret input").attr('disabled',true);
    $("#contenedor-consumer-secret").css('display','none');

    $("#contenedor-access-token input").val("");
    $("#contenedor-access-token input").attr('disabled',true);
    $("#contenedor-access-token").css('display','none');

    $("#contenedor-access-token-secret input").val("");
    $("#contenedor-access-token-secret input").attr('disabled',true);
    $("#contenedor-access-token-secret").css('display','none');

    $("#contenedor-tienda-google-id input").val("");
    $("#contenedor-tienda-google-id input").attr('disabled',true);
    $("#contenedor-tienda-google-id").css('display','none');

    $("#contenedor-credenciales-google input").val("");
    $("#contenedor-credenciales-google input").attr('disabled',true);
    $("#contenedor-credenciales-google").css('display','none');
}
//Idiomas
$(document).on('click','.abreIdiomaModal', function(){
    var id = this.id.substr(7,25);
    console.log(id);
    $("#_method").val("PUT");
    $("#formIdioma").attr('action',"tiendas/idiomas/"+id);
    $("#btnDeleteIdioma").css("display","block");
    $("#btnDeleteIdioma").attr('onclick',"document.getElementById('deleteIdioma-"+id+"').submit()");
    
    $(".campo").val("");
    $.ajax({
        url: "tiendas/idiomas/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#fichaIdiomaLabel").html("Edición de tienda "+data['name']);
            $("#nameIdioma").val(data['name']);
            $.each(data,function(index,value){
                $("#"+index.toLowerCase()).val(value);
            });
        }
    }); 
});
$(document).on('click','.nuevoIdiomaModal', function(){
    $("#_method").val("POST");
    $("#formIdioma").attr('action',"tiendas/idiomas");
    $("#fichaIdiomaLabel").html("Creación de nuevo idioma");
    $("#btnDeleteIdioma").css("display","none");

    $(".campo").val("");

});
$(document).on('change','#nameIdioma',function(){
    var idiomas = {"Español":"ES","Inglés":"EN","Alemán":"DE", "Francés":"FR","Portugués":"PT"};
    var valor = $(this).val();
    $("#acronym").val(idiomas[valor]);
});



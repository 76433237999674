//Users
$('.nuevoUserModal').on("click", function(){
    alert("HOLA");
    $("#_method").val("POST");
    $("#formUser").attr('action',"users");
    $("#fichaUserLabel").html("Creación de nuevo usuario");
    $(".campo").val("");
});

$('.abreCambiarPassword').on("click", function(){
    var id = this.id.substr(20,20);
    console.log(id),
    $("#_method2").val("POST");
    $("#formCambiarPassword").attr('action',"users");
    $("#cambiarPasswordLabel").html("Cambio de passoword para usuario "+id);
    $(".campo").val("");
});

$(document).on('click','.btn-delete-user',function(){
    if(confirm("Estás seguro que deseas eliminar a este usuario?") == true){
        $("#form-delete-user-"+this.id.substr(16,20)).submit();
    }
});
//Set atributos
$("#set-atributos").ready(function(){
    $("#grupo-atributos").empty();
    $.ajax({
        url: "setatributos/aux",
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#set-atributos").append("<h4 class='mt-auto'>Sets:</h4>");
            $.each(data,function(index,value){
                $("#set-atributos").append("<div class='etiquetas d-flex justify-content-between ms-3'><p id='set-"+value.id+"' class='mb-0 abreSetatributoModal' data-bs-toggle='modal' data-bs-target='#fichaSetatributo'>"+value.name+"</p><i id='deleteset-"+value.id+"' class='bi bi-x'></i></div>");
            });
            
        }
    });
});
$(document).on("click",".abreSetatributoModal",function(){
    $("#formSetatributo #nameset").val($(this).html());
});
$(document).on("click","#set-atributos .etiquetas i",function(){
    var id = this.id.substr(10,20);
    if(confirm("¿Desea borrar el set de atributos?") == true){
        $.ajax({
            url: "setatributos/delete/"+id,
            type: "GET",
            dataType: "json",
            success: function(data){
                console.log(data);
            }
        });
        $(this).parent().remove();
    }
});
$(document).on('click',".nuevoSetatributoModal",function(){
    $("#_method").val("POST");
    $("#formSetatributo").attr('action',"setatributos");
    $("#fichaSetatributoLabel").html("Creación de nuevo set de atributos");
    $(".campo").val("");
});
$(document).on('click',".abreSetatributoModal",function(){
    var id = this.id.substr(4,25);
    $("#_methodset").val("PUT");
    $("#formSetatributo").attr('action',"setatributos/"+id);
});

//Grupo atributos
$("#grupo-atributos").ready(function(){
    $("#grupo-atributos").empty();
    $.ajax({
        url: "grupoatributos/aux",
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#grupo-atributos").append("<h4 class='mt-auto'>Grupos:</h4>");
            $.each(data,function(index,value){
                $("#grupo-atributos").append("<div class='etiquetas d-flex justify-content-between ms-3'><p id='grupo-"+value.id+"' class='mb-0 abreGrupoatributoModal' data-bs-toggle='modal' data-bs-target='#fichaGrupoatributo'>"+value.name+"</p><i id='deletegrupo-"+value.id+"' class='bi bi-x'></i></div>");
            });
            
        }
    });
});
$(document).on("click",".abreGrupoatributoModal",function(){
    var id = this.id.substr(6,25);
    $("#_methodgrupo").val("PUT");
    $("#formGrupoatributo").attr('action',"grupoatributos/"+id);
    $("#fichaGrupoatributoLabel").html("Edición de grupo de atributos");
    $(".campo").val("");
    $("#sets-contenedor").empty();
    $("#sets-contenedor").append("<label>Sets de atributos</label><select class='campo form-control' multiple name='sets_atributos[]' id='set-atributos' style='text-align:center'></select>");
    $.ajax({
        url: "grupoatributos/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            var choicesMulti = Array();
            $.each(data['sets'],function(index,value){
                var selected = false;
                $.each(data['assigned_set_grupo_atributo'],function(index2,value2){
                    if(value2['id'] == value['id']){
                        selected = true;
                    }
                });
                choicesMulti.push({
                    value:value['id'],
                    label:value['name'],
                    selected:selected,
                });
            });
            var selectSets = new Choices('#set-atributos', {
                choices: choicesMulti,
                removeItemButton: true,
                maxItemCount:-1,
                searchResultLimit:-1,
                renderChoiceLimit:-1,
                searchEnabled:true,
                noResultsText: 'Sin resultados',
                noChoicesText:'Sin resultados',
                placeholderValue:'Selecciona...',
                itemSelectText: "",
                shouldSort:true,
                fuseOptions: {
                    includeScore:true,
                    includeMatches:true,
                    findAllMatches:true,
                    keys:['label'],
                    threshold:1.0,
                }
            }); 
            $("#namegrupo").val(data['name']);
        }
    });
});
$(document).on("click","#grupo-atributos .etiquetas i",function(){
    var id = this.id.substr(12,20);
    if(confirm("¿Desea borrar el grupo de atributos?") == true){
        $.ajax({
            url: "grupoatributos/delete/"+id,
            type: "GET",
            dataType: "json",
            success: function(data){
                console.log(data);
            }
        });
        $(this).parent().remove();
    }
});
$(document).on('click',".nuevoGrupoatributoModal",function(){
    $("#_method").val("POST");
    $("#formGrupoatributo").attr('action',"grupoatributos");
    $("#fichaGrupoatributoLabel").html("Creación de nuevo grupo de atributos");
    $(".campo").val("");
    $("#sets-contenedor").empty();
    $("#sets-contenedor").append("<label>Sets de atributos</label><select class='campo form-control' multiple name='sets_atributos[]' id='set-atributos' style='text-align:center'></select>");
    $.ajax({
        url: "setatributos/aux",
        type: "GET",
        dataType: "json",
        success: function(data){
            var choicesMulti = Array();
            $.each(data,function(index,value){
                var selected = false;
                choicesMulti.push({
                    value:value['id'],
                    label:value['name'],
                    selected:selected,
                });
            });
            console.log(choicesMulti);
            var selectSets = new Choices('#set-atributos', {
                choices: choicesMulti,
                removeItemButton: true,
                maxItemCount:-1,
                searchResultLimit:-1,
                renderChoiceLimit:-1,
                searchEnabled:false,
                noResultsText: 'Sin resultados',
                noChoicesText:'Sin resultados',
                placeholderValue:'Selecciona...',
                itemSelectText: "",
                shouldSort:true,
            });
            console.log(selectSets);
            


            
        }
    });
});

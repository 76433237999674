$(document).on('click','.top10productos',function(){
    var id = this.id.substr(12,20);
    $(".top-productos").empty();
    $(".top-productos").append(`
        <div class='linea-lista border-bottom' style='position:sticky; top:0; background-color:#fff'>
            <div class="list-home-posicion m-auto text-center m-auto fw-bolder">Posición</div>                
            <div class="list-home-sku m-auto text-center m-auto fw-bolder">SKU</div>
            <div class="list-home-nombre m-auto text-center m-auto fw-bolder">Nombre</div>
            <div class="list-home-precio m-auto text-center m-auto fw-bolder">Precio</div>
            <div class="list-home-oferta m-auto text-center m-auto fw-bolder">Oferta</div>
            <div class="list-home-stock m-auto text-center m-auto fw-bolder">Stock</div>
            <div class="list-home-vendidos m-auto text-center m-auto fw-bolder">Vendidos</div>
        </div>
    `);
    $.ajax({
        url: "top10productos/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $.each(data,function(index,value){
                var html = `
                    <div id="linea-`+value['sku']+`" class='linea-lista border-top'>
                `;
                if(value['producto'] != null){
                    html += `
                        <div class="list-home-posicion m-auto text-center m-auto">`+parseInt(index+1)+`º</div>
                        <div class="list-home-sku m-auto text-center m-auto">`+value['sku']+`</div>
                        <div class="list-home-nombre m-auto text-center m-auto">`+value['producto']['name']+`</div>
                        <div class="list-home-precio m-auto text-center m-auto">`+value['producto']['price']+`</div>
                        <div class="list-home-oferta m-auto text-center m-auto">`+value['producto']['special_price']+`</div>
                        <div class="list-home-stock m-auto text-center m-auto">`+value['producto']['stock']+`</div>
                        <div class="list-home-vendidos m-auto text-center m-auto">`+value['cant']+`</div>
                                
                    `;
                }else{
                    html += `
                        <div class="list-home-posicion m-auto text-center m-auto">`+parseInt(index+1)+`º</div>
                        <div class="list-home-sku m-auto text-center m-auto">`+value['sku']+`</div>
                        <div class="list-home-nombre m-auto text-center m-auto">Not found 404</div>
                        <div class="list-home-precio m-auto text-center m-auto">Not found 404</div>
                        <div class="list-home-oferta m-auto text-center m-auto">Not found 404</div>
                        <div class="list-home-stock m-auto text-center m-auto">Not found 404</div>
                        <div class="list-home-vendidos m-auto text-center m-auto">`+value['cant']+`</div>
                    `;
                }
                html += `
                            </div>
                `;
                $(".top-productos").append(html);
            });
        }
    });
});
$(document).on('click','.top10clientes',function(){
    var id = this.id.substr(13,20);
    $(".top-clientes").empty();
    $(".top-clientes").append(`
        <div class='linea-lista border-bottom' style='position:sticky; top:0; background-color:#fff'>
            <div class="listclient-home-posicion m-auto text-center m-auto fw-bolder">Posición</div>                
            <div class="listclient-home-id m-auto text-center m-auto fw-bolder">#</div>
            <div class="listclient-home-nombre m-auto text-center m-auto fw-bolder">Nombre</div>
            <div class="listclient-home-pedidos m-auto text-center m-auto fw-bolder">Pedidos</div>
        </div>
    `);
    $.ajax({
        url: "top10clientes/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            console.log(data);
            $.each(data,function(index,value){
                var html = `
                    <div id="linea-`+value['cliente']['id']+`" class='linea-listcliente border-top'>
                `;
                if(value['cliente'] != null){
                    html += `
                        <div class="listclient-home-posicion m-auto text-center m-auto">`+parseInt(index+1)+`º</div>
                        <div class="listclient-home-id m-auto text-center m-auto">`+value['cliente_id_origin']+`</div>
                        <div class="listclient-home-nombre m-auto text-center m-auto">`+value['cliente']['name']+` `+value['cliente']['lastname']+`</div>
                        <div class="listclient-home-pedidos m-auto text-center m-auto">`+value['pedidos']+`</div>
                    `;
                }else{
                    html += `
                        <div class="listclient-home-posicion m-auto text-center m-auto">`+parseInt(index+1)+`º</div>
                        <div class="listclient-home-id m-auto text-center m-auto">`+value['cliente_id_origin']+`</div>
                        <div class="listclient-home-nombre m-auto text-center m-auto">Not found 404</div>
                        <div class="listclient-home-pedidos m-auto text-center m-auto">`+value['pedidos']+`</div>
                    `;
                }
                html += `
                            </div>
                `;
                $(".top-clientes").append(html);
            });
        }
    });
});
$(document).on('click','.productoshoy',function(){
    var id = this.id.substr(13,20);
    $(".productos-vendidos").empty();
    $(".productos-vendidos").append(`
        <div class='linea-lista border-bottom' style='position:sticky; top:0; background-color:#fff'>
            <div class="list-home-posicion m-auto text-center m-auto fw-bolder">Posición</div>                
            <div class="list-home-sku m-auto text-center m-auto fw-bolder">SKU</div>
            <div class="list-home-nombre m-auto text-center m-auto fw-bolder">Nombre</div>
            <div class="list-home-precio m-auto text-center m-auto fw-bolder">Precio</div>
            <div class="list-home-oferta m-auto text-center m-auto fw-bolder">Oferta</div>
            <div class="list-home-stock m-auto text-center m-auto fw-bolder">Stock</div>
            <div class="list-home-vendidos m-auto text-center m-auto fw-bolder">Vendidos</div>
        </div>
    `);
    $.ajax({
        url: "productoshoy/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            console.log(data);
            $.each(data,function(index,value){
                var html = `
                    <div id="linea-`+value['sku']+`" class='linea-lista border-top'>
                `;
                if(value != null){
                    html += `
                        <div class="list-home-posicion m-auto text-center">`+parseInt(index+1)+`º</div>
                        <div class="list-home-sku m-auto text-center">`+value['sku']+`</div>
                        <div class="list-home-nombre m-auto text-center">`+value['name']+`</div>
                        <div class="list-home-precio m-auto text-center">`+value['price']+`</div>
                        <div class="list-home-oferta m-auto text-center">`+value['special_price']+`</div>
                        <div class="list-home-stock m-auto text-center">`+value['stock']+`</div>
                        <div class="list-home-vendidos m-auto text-center">`+value['cantidad']+`</div>
                                
                    `;
                }else{
                    html += `
                        <div class="list-home-posicion m-auto text-center">`+parseInt(index+1)+`º</div>
                        <div class="list-home-sku m-auto text-center">`+value['sku']+`</div>
                        <div class="list-home-nombre m-auto text-center">Not found 404</div>
                        <div class="list-home-precio m-auto text-center">Not found 404</div>
                        <div class="list-home-oferta m-auto text-center">Not found 404</div>
                        <div class="list-home-stock m-auto text-center">Not found 404</div>
                        <div class="list-home-vendidos m-auto text-center">`+value['cantidad']+`</div>
                    `;
                }
                html += `
                            </div>
                `;
                $(".productos-vendidos").append(html);
            });
        }
    });
});

$(".card-home-tienda").ready(function(){
    $.each($(".card-home-tienda"),function(index,value){
        var tienda_id = value.id.substr(7,10);
        $.ajax({
            url: "home/pedidos/"+tienda_id,
            type: "GET",
            dataType: "json",
            success: function(data){
                console.log(data);
                $("#tienda-"+tienda_id+" .total_pedidos_ano").html(data['total_pedidos_ano']);
                $("#tienda-"+tienda_id+" .total_pedidos_hoy").html(data['total_pedidos_hoy']);
                $("#tienda-"+tienda_id+" .pedidos_ptes_pago").html("<a href='/pedidos?campofiltro=payment&valorfiltro=banktransfer'>"+data['pedidos_ptes_pago']+"</a>");
                $("#tienda-"+tienda_id+" .pedidos_preparando").html("<a href='/pedidos?campofiltro=status&valorfiltro=processing'>"+data['pedidos_preparando']+"</a>");
                $("#tienda-"+tienda_id+" .productos_pedidos_hoy").html("<a class='productoshoy' id='productoshoy-"+tienda_id+"' data-bs-toggle='modal' data-bs-target='#listaproductosvendidos' href='#'>"+data['productos_pedidos_hoy']+"</a>");
                $("#tienda-"+tienda_id+" .promedio_pedidos").html(data['promedio_pedidos']+"€");
                $("#tienda-"+tienda_id+" .ingresos_ayer").html(data['ingresos_ayer']+"€");
                $("#tienda-"+tienda_id+" .beneficios_ayer").html(data['beneficios_ayer']+"€");
                $("#tienda-"+tienda_id+" .ingresos_hoy").html(data['ingresos_hoy']+"€");
                $("#tienda-"+tienda_id+" .beneficios_hoy").html(data['beneficios_hoy']+"€");
            }
        });
    });
});

$(document).ready(function(){
    $.ajax({
        url: "home/infogeneral",
        type: "GET",
        dataType: "json",
        success: function(data){
            $(".total_productos").html(data['total_productos']);
            $(".total_categorias").html(data['total_categorias']);
            $(".total_atributos").html(data['total_atributos']);
            $(".total_tasas").html(data['total_tasas']);
            $(".productos_sin_stock a").html(data['productos_sin_stock']);
            $(".productos_habilitados a").html(data['productos_habilitados']);
            $(".productos_deshabilitados a").html(data['productos_deshabilitados']);
            $(".productos_sin_categorizar a").html(data['productos_sin_categorizar']);
            $(".productos_sin_imagenes a").html(data['productos_sin_imagenes']);
        }
    });
});


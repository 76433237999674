//Pedidos
$(document).on('click','.abreClienteModal', function(){
    
    var id = this.id.substr(8,25);
    //Formularios
    //Vaciado de campos
    $(".campo").val("");
   //Consulta producto
    $.ajax({
        url: "clientes/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            console.log(data)
            //HEADER
            $(".modal-header").empty();
            $(".comprobacion").remove();
            $(".modal-header").append("<h5 class='modal-title' id='fichaClienteLabel'>Cliente #"+data['id_origin']+"</h5>");
            $("#direcciones-envio").empty();
            $("#pedidos").empty();

            $("#cliente").val(data['name']+" "+data['lastname']);
            $("#email").val(data['email']);
            $("#telephone").val(data['telephone']);
            $("#nif").val(data['nif']);
            $("#direccion").val(data['street']+" , "+data['postcode']+" , "+data['region']);
            $("#postcode").val(data['postcode']);
            $("#city").val(data['city']);
            $("#region").val(data['region']);
            $.each(data['direcciones_envio'],function(index,value){
                $("#direcciones-envio").append(`
                    <div class="d-flex flex-row justify-content-between">
                        <div class="form-group" style="width:30%">
                            <label>Nombre</label>
                            <input type='text' readonly required class="campo form-control" name='envio_cliente' id="envio_cliente" value="`+value['name']+value['lastname']+`" style='text-align:center'>
                        </div>
                        <div class="form-group" style="width:30%">
                            <label>Email</label>
                            <input type='text' readonly required class="campo form-control" name='shipping_email' id="shipping_email" value="`+value['email']+`" style='text-align:center'>
                        </div>
                        <div class="form-group" style="width:30%">
                            <label>Teléfono</label>
                            <input type='text' readonly required class="campo form-control" name='shipping_telephone' id="shipping_telephone" value="`+value['telephone']+`" style='text-align:center'>
                        </div>
                    </div>
                    <div class="form-group w-100">
                        <label>Dirección</label>
                        <input type='text' readonly required class="campo form-control" name='envio_direccion' id="envio_direccion" value="`+value['street']+` , `+value['postcode']+` , `+value['region']+`" style='text-align:center'>
                    </div>
                    <div class="d-flex flex-row justify-content-between">
                        <div class="form-group" style="width:20%">
                            <label>Código postal</label>
                            <input type='text' readonly required class="campo form-control" name='shipping_postcode' id="shipping_postcode" value="`+value['postcode']+`" style='text-align:center'>
                        </div>
                        <div class="form-group" style="width:20%">
                            <label>Población</label>
                            <input type='text' readonly required class="campo form-control" name='shipping_city' id="shipping_city" value="`+value['city']+`" style='text-align:center'>
                        </div>
                        <div class="form-group" style="width:20%">
                            <label>Provincia</label>
                            <input type='text' readonly required class="campo form-control" name='shipping_region' id="shipping_region" value="`+value['region']+`" style='text-align:center'>
                        </div>
                        <div class="form-group" style="width:20%">
                            <label>País</label>
                            <input type='text' readonly required class="campo form-control" name='shipping_country' id="shipping_country" value="`+value['country']+`" style='text-align:center'>
                        </div>
                    </div>
                    <hr>
                `);
            });
            $("#pedidos").append(`
                    <div class="cabecera-lista">
                        <div class="text-center fw-bolder" style="width:20%" >#</div>
                        <div class="text-center fw-bolder" style="width:20%" >Fecha</div>
                        <div class="text-center fw-bolder" style="width:20%" >Estado</div>
                        <div class="text-center fw-bolder" style="width:20%" >Productos</div>
                        <div class="text-center fw-bolder" style="width:20%" >Valor</div>
                        <div class="text-center fw-bolder" style="width:2%" > </div>
                    </div>                       
                `);
            $.each(data['pedidos'],function(index,value){
                $("#pedidos").append(`
                    <div class="registro-lista">
                        <div class="text-center" style="width:20%">`+value['increment_id']+`</div>
                        <div class="text-center" style="width:20%">`+value['created_at_origin']+`</div>
                        <div class="text-center" style="width:20%">`+value['state']+`</div>
                        <div class="text-center" style="width:20%">uds</div>
                        <div class="text-center" style="width:20%">`+value['base_total_invoiced']+`</div>
                        <div class="text-center" style="width:2%"><a href="/pedidos?campofiltro=increment_id&valorfiltro=`+value['increment_id']+`"><i class="bi bi-eye-fill"></a></i></div>
                    </div>                       
                `);
            });
        },
        complete: function(){
        }

    }); 
});


//Productos
$(document).on('click','.abreProductoModal', function(){
    
    var id = this.id.substr(9,25);
    var optionsTasas = "";
    //Formularios
    $("#_method").val("PUT");
    $("#formProducto").attr('action',"productos/"+id);
    $("#btnDeleteProducto").css("display","block");
    $("#btnDeleteProducto").attr('id',"btnDelete-"+id);
    $("#sku").attr('readonly',true);
    //Vaciado de campos
    $("#contenedor-videos").empty();
    $(".campo").val("");
    configurablesInicio();
    bundlesInicio();
    //Id producto
    $("#producto_id").val(id);
   //Consulta producto
    $.ajax({
        url: "productos/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            //HEADER
            $(".modal-header").empty();
            $(".comprobacion").remove();
            $(".modal-header").append("<h5 class='modal-title' id='fichaProductoLabel'>Edición de producto "+data['name']+"</h5>");
            //Names
            $("#contenedor-names").empty();
            $("#contenedor-names").append("<div class='form-group idioma idioma1 idioma-seleccionado'><label>Nombre*</label><input type='text' required class='campo form-control' name='name' id='name' style='text-align:center'></div>");
            //Url keys
            $("#contenedor-url-keys").empty();
            $("#contenedor-url-keys").append("<div class='idioma idioma1 idioma-seleccionado'><div class='form-group d-flex'><div style = 'width:95%'><label>URL Key*</label><input type='text' required class='campo form-control' name='url_key' id='url_key' style='text-align:center'></div><a href='#' class='btn mt-3 changeURL' style='width:5%'><i class='bi bi-arrow-clockwise'></i></a></div></div>");
            //Descriptions
            tinymce.remove("textarea");
            $("#contenedor-text-areas").empty();
            $("#contenedor-text-areas").append("<div id ='contenedor-description' class='form-group idioma idioma1 idioma-seleccionado'><label>Descripción</label><textarea class='campo form-control textarea-core' name='description' id='description'></textarea></div>");
            $("#contenedor-text-areas").append("<div id ='contenedor-shortdescription' class='form-group idioma idioma1 idioma-seleccionado'><label>Descripción corta</label><textarea class='campo form-control textarea-core' name='short_description' id='short_description'></textarea></div>");
            //RESTO
            $.each(data,function(index,value){
                if(index == "atributos"){
                    $(".atributos").empty();
                    $(".atributos").append("<div class='w-100'><h2>Atributos</h2></div><hr class='w-100 mt-0'><div class='d-flex justify-content-center w-100 mb-3 producto-mobile-set'><label style='width:11%;padding-top:0.5rem'>Set de atributos:</label><select class='form-control' style='width:15%' name='setattribute_id' id='setattribute_id'></select></div>");
                    $(".atributos").append("<div id='atributos-list' class='d-flex flex-row justify-content-left w-100 flex-wrap'></div>");
                    $.each(value,function(index2,value2){
                        if(value2['type'] == "multiselect"){
                            var multiselect = "<div class='form-group atributo-contenedor' id='atributo-"+value2['code']+"'><label>"+value2['name']+"</label><select name='atributo["+value2['id']+"][]' multiple class='form-control' id='multiselect-atributo-"+value2['id']+"'></select></div>";
                            $("#atributos-list").append(multiselect);
                            var choicesMulti = Array();
                            $.each(value2['valoresatributo'],function(index3,value3){
                                if(value3['selected'] == "selected"){
                                    var selected = true;
                                }else{
                                    var selected = false;
                                }
                                choicesMulti.push({
                                    value:value3['id'],
                                    label:value3['name'],
                                    selected:selected,
                                });
                            });
                            var selectAtributo = new Choices("#multiselect-atributo-"+value2['id'], {
                                choices: choicesMulti,
                                removeItemButton: true,
                                maxItemCount:-1,
                                searchResultLimit:-1,
                                renderChoiceLimit:-1,
                                searchChoices:true,
                                searchFields:"label",
                                noResultsText: 'Sin resultados',
                                noChoicesText:'Sin resultados',
                                placeholderValue:'Selecciona...',
                                itemSelectText: "",
                                shouldSort:true,
                            }); 
                        }else if(value2['type'] == "select"){
                            var select = "<div class='form-group atributo-contenedor' id='atributo-"+value2['code']+"'><label>"+value2['name']+"</label><select name='atributo["+value2['id']+"]' class='form-control'>";
                            select += "<option value='0'>Nada seleccionado</option>";
                            $.each(value2['valoresatributo'],function(index3,value3){
                                select += "<option "+value3['selected']+" value='"+value3['id']+"'>"+value3['name']+"</option>";
                            });
                            select += "</select></div>";
                            $("#atributos-list").append(select);                             
                        }else if(value2['type'] == "text"){
                            $("#atributos-list").append("<div class='form-group atributo-contenedor' id='atributo-"+value2['code']+"'><label>"+value2['name']+"</label><input name='atributo_varchar["+value2['id']+"]' type='text' class='form-control' style='text-align:center' value='"+value2['valor']+"'></div>");
                        }else if(value2['type'] == "boolean"){
                            var select = "<div class='form-group atributo-contenedor' id='atributo-"+value2['code']+"'><label>"+value2['name']+"</label><select name='atributo_boolean["+value2['id']+"]' class='form-control'>";
                            if(value2['valor'] == 0){
                                select += "<option value=''>Nada seleccionado</option><option selected value='0'>NO</option><option value='1'>SI</option>";
                            }else if(value2['valor'] == 1){
                                select += "<option value=''>Nada seleccionado</option><option value='0'>NO</option><option selected value='1'>SI</option>";
                            }else{
                                select += "<option selected value=''>Nada seleccionado</option><option value='0'>NO</option><option value='1'>SI</option>";
                            }
                            select += "</select></div>";
                            $("#atributos-list").append(select);
                        }else if(value2['type'] == "textarea"){
                            $("#contenedor-text-areas").append("<div id ='contenedor-"+value2['code']+"' class='form-group atributo-text-area-contenedor'><label>"+value2['name']+"</label><textarea class='campo form-control textarea-atr' name='atributo_textarea["+value2['id']+"]'>"+value2['valor']+"</textarea></div>");
                        }
                    });
                }else if(index == "categorias"){
                    $("#categorias-container").empty();
                    $("#categorias-container").append("<label>Categorias</label><select class='campo form-control' multiple   name='categorias[]' id='categorias' style='text-align:center'></select>");
                    var choicesMulti = Array();
                    $.each(value,function(index2,value2){
                        var selected = false;
                        $.each(data['assigned_categorias'],function(index3,value3){
                            if(value3['id'] == value2['id']){
                                selected = true;
                            }
                        });
                        choicesMulti.push({
                            value:value2['id'],
                            label:value2['name'],
                            selected:selected,
                        });
                    });
                    var selectCategorias = new Choices('#categorias', {
                        choices: choicesMulti,
                        removeItemButton: true,
                        maxItemCount:-1,
                        searchResultLimit:-1,
                        renderChoiceLimit:-1,
                        searchEnabled:true,
                        noResultsText: 'Sin resultados',
                        noChoicesText:'Sin resultados',
                        placeholderValue:'Selecciona...',
                        itemSelectText: "",
                        shouldSort:true,
                        fuseOptions: {
                            includeScore:true,
                            includeMatches:true,
                            findAllMatches:true,
                            keys:['label'],
                            threshold:1.0,
                        }
                    }); 
                }else if(index == "tasas"){
                    $.each(value,function(index2,value2){
                        if(data['tasa_id'] == value2['id']){
                            optionsTasas += "<option selected value='"+value2['id']+"'>"+value2['name']+"</option>";
                        }else{
                            optionsTasas += "<option value='"+value2['id']+"'>"+value2['name']+"</option>";
                        }
                    });
                    $("#tasa_id").append(optionsTasas);

                }else if(index == "imagenes"){
                    $("#carouselImagenesProductos .carousel-inner").empty();
                    if(value.length == 0){
                        $("#carouselImagenesProductos .carousel-inner").append("<div class='carousel-item active'><img src='storage/product_images/no_img_avaliable.jpg' class='d-block' width='300px'></div>");
                    }else{
                        $.each(value,function(index2,value2){
                            if(index2 == 0){
                                var active = "active";
                            }else{
                                var active = "";
                            }
                            var carouselItem = "<div class='carousel-item "+active+"'>";
                            carouselItem += "<img id='image-"+value2['id']+"' src='storage/"+value2['url']+"' class='d-block' width='300px'>";
                            carouselItem += "<button  class='borraImagen' id='imgdel-"+value2['id']+"' type='button'><i class='bi bi-trash3'></i></button>";
                            carouselItem += "<input class='posicionImagen form-control' value='"+value2['posicion']+"' name='posicionimagen["+value2['id']+"]' id='imgpos-"+value2['id']+"'>"
                            carouselItem += "</div>";
                            $("#carouselImagenesProductos .carousel-inner").append(carouselItem);
                        });
                    }
                }else if(index == "videos"){
                    $.each(value,function(index2,value2){
                        $("#contenedor-videos").append("<div class='d-flex mb-3'><a class='btn deleteinputvideo' href='#'><i class='bi bi-x-circle'></i></a><input class='form-control input-video' value='"+value2['url']+"' id='video-"+value2['id']+"' name='videos[]'><a  class='btn visor' target='_blank' href='"+value2['url']+"'><i class='bi bi-eye'></i></a></div>");
                    });
                }else if(index == "sets_atributos"){
                    $.each(value,function(index2,value2){
                        if(value2['id'] == data['setattribute_id']){
                            $("#setattribute_id").append("<option selected value='"+value2['id']+"'>"+value2['name']+"</option>");
                        }else{
                            $("#setattribute_id").append("<option value='"+value2['id']+"'>"+value2['name']+"</option>");
                        }
                    });
                }else if(index == "url_key"){
                    if(value == null){
                        $("#url_key").val(data['generada_url_key']);
                    }else{
                        $("#url_key").val(value);
                    }
                    $(".changeURL").attr("id","changeURL-"+id);
                }else if(index == "childs" && value.length != 0){
                    $("#tipo_configurable > option[value='parent']").attr('selected','selected');
                    changeConfigurable(value);
                }else if(index == "parent" && value.length != 0){
                    $("#tipo_configurable > option[value='child']").attr('selected','selected');
                    changeConfigurable(value);
                }else if(index == "atributos_clasificadores"){
                    //Select atributos productos configurables
                    var choicesMulti = Array();
                    $.each(data['atributos'],function(index2,value2){
                        if(value2['type'] == "select"){
                            var selected = false;
                            $.each(value,function(index3,value3){
                                if(value3['id'] == value2['id']){
                                    selected = true;
                                }
                            });
                            choicesMulti.push({
                                value:value2['id'],
                                label:value2['name'],
                                selected:selected,
                            });
                        }
                    });
                    var selectAtributosConfigurable = new Choices("#atributos_configurable", {
                        choices: choicesMulti,
                        removeItemButton: true,
                        maxItemCount:-1,
                        searchResultLimit:-1,
                        renderChoiceLimit:-1,
                        searchFields:"label",
                        noResultsText: 'Sin resultados',
                        noChoicesText:'Sin resultados',
                        placeholderValue:'Selecciona...',
                        itemSelectText: "",
                    }); 
            
                }else if(index == "bundleoptions" && value.length != 0){
                    $.each(value,function(index2,value2){
                        console.log(value2);
                        if(value2['type'] == 'select'){
                            var selectedSelect   = "selected";
                            var selectedRadio    = "";
                            var selectedCheckbox = "";
                            var selectedMultiple = "";
                        }else if(value2['type'] == 'radio'){
                            var selectedSelect   = "";
                            var selectedRadio    = "selected";
                            var selectedCheckbox = "";
                            var selectedMultiple = "";
                        }else if(value2['type'] == 'checkbox'){
                            var selectedSelect   = "";
                            var selectedRadio    = "";
                            var selectedCheckbox = "selected";
                            var selectedMultiple = "";
                        }else if(value2['type'] == 'multi'){
                            var selectedSelect   = "";
                            var selectedRadio    = "";
                            var selectedCheckbox = "";
                            var selectedMultiple = "selected";
                        }
                        if(value2['required'] == 1){
                            var checkedRequired = "checked";
                        }else{
                            var checkedRequired = "";
                        }
                        $(".bundles .options").append(`
                            <div class='d-flex w-100 form-group'>
                                <input type='hidden' value='`+value2['id']+`' class='id-bundle'>
                                <i class='bi bi-caret-down me-3' data-bs-toggle='collapse' data-bs-target='#collapse-`+data['sku']+`-`+value2['id']+`'></i>    
                                <input type='text' name='namebundle[`+value2['id']+`]' class='form-control w-50 me-3' value='`+value2['title']+`'>
                                <select class='form-control w-25 me-3' name='typebundle[`+value2['id']+`]'>
                                    <option `+selectedSelect+` value='select'>Drop-down</option>
                                    <option `+selectedRadio+` value='radio'>Radio buttons</option>
                                    <option `+selectedCheckbox+` value='checkbox'>Checkbox</option>
                                    <option `+selectedMultiple+` value='multi'>Multiple select</option>
                                </select>
                                <div class="form-check m-auto">
                                    <input class="form-check-input" `+checkedRequired+` type="checkbox" value="true" name='requiredbundle[`+value2['id']+`]'>
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Requerido
                                    </label>
                                </div>
                                <div>
                                    <a class='btn addinputskubundle' href='#'>
                                        <i class='bi bi-plus-circle'></i>
                                    </a>
                                </div>
                            </div>
                            <div class='collapse' id='collapse-`+data['sku']+`-`+value2['id']+`'></div>
                        `);
                        $.each(value2['assigned_productos_bundleoptions'],function(index3,value3){
                            $("#collapse-"+data['sku']+"-"+value2['id']).append(`
                                <div class='d-flex w-100 justify-content-between form-group'>
                                    <div class='ms-2 mt-4' style='width:3%'>
                                        <a class='btn deleteinputskubundle' href='#'>
                                            <i class='bi bi-x-circle'></i>
                                        </a>
                                    </div>
                                    <div style='width:10%' class='container-sku-bundle'>
                                        <label>SKU</label>
                                        <input class='form-control sku-bundle' name='skusbundle[`+value2['id']+`][]' type='text' value='`+value3['sku']+`'>
                                    </div>
                                    <div class='w-50 container-name-bundle'>
                                        <label>Nombre</label>
                                        <input class='form-control name-bundle' type='text' disabled value='`+value3['name']+`'>
                                    </div>
                                    <div style='width:10%' class='container-price-bundle'>
                                        <label>Precio</label>
                                        <input class='form-control price-bundle' type='number' step='0.01' disabled value='`+value3['price']+`'>
                                    </div>
                                    <div style='width:10%' class='container-special-price-bundle'>
                                        <label>Precio especial</label>
                                        <input class='form-control special-price-bundle' type='number' step='0.01' disabled value='`+value3['special_price']+`'>
                                    </div>
                                    <div style='width:10%' class='container-stock-bundle'>
                                        <label>Stock</label>
                                        <input class='form-control stock-bundle' type='number' step='1.00' disabled value='`+value3['stock']+`'>
                                    </div>
                                </div>
                            `);
                        });
                    });
                }else if(index == "typemagento"){
                    if(value == "simple"){
                        $("#type").val('simple').change();
                        $('#type option[value="bundle"]').attr('disabled','disabled');
                    }else if(value == "configurable"){
                        $("#type").val('configurable').change();
                        $('#type option[value="bundle"]').attr('disabled','disabled');
                        $('#type option[value="simple"]').attr('disabled','disabled');
                    }else if(value == "bundle"){
                        $("#type").val('bundle').change();
                        $('#type option[value="simple"]').attr('disabled','disabled');
                        $('#type option[value="configurable"]').attr('disabled','disabled');
                    }
                }else if(index == "traducciones"){
                    //Traducciones
                    $.ajax({
                        url: "tiendas/aux",
                        type: "GET",
                        dataType: "json",
                        success: function(data2){
                            $.each(data2,function(index,value){
                                $(".modal-header").append("<a href='#' id='comparatiendas-"+data['id']+"-"+value['id']+"' class='btn compara-tiendas' title='Comparar con tienda "+value['name']+"' style='width:5%'><i class='bi bi-arrow-clockwise'></i></a>");
                                if(value['idiomas']){
                                    $.each(value['idiomas'],function(index2,value2){
                                        if(index2 == 0){
                                            $(".modal-header").append("<a href='#' id='idioma-"+value2['id']+"' class='btn botonidioma botonidioma-seleccionado' title='' style='width:5%'>"+value2['acronym']+"</a>");
                                        }else{
                                            $(".modal-header").append("<a href='#' id='idioma-"+value2['id']+"' class='btn botonidioma' title='' style='width:5%'>"+value2['acronym']+"</a>");
                                            //Names
                                            $("#contenedor-names").append("<div class='form-group idioma idioma"+value2['id']+" idioma-noseleccionado'><label>Nombre "+value2['acronym']+"*</label><input type='text' class='campo form-control' name='traducciones["+value2['id']+"][name]' id='idioma"+value2['id']+"-name' style='text-align:center'></div>");
                                            //Url keys
                                            $("#contenedor-url-keys").append("<div class='idioma idioma"+value2['id']+" idioma-noseleccionado'><div class='form-group d-flex'><div style = 'width:95%'><label>URL Key "+value2['acronym']+"*</label><input type='text' class='campo form-control' name='traducciones["+value2['id']+"][url_key]' id='idioma"+value2['id']+"-url_key' style='text-align:center'></div><a href='#' class='btn mt-3 changeURL' style='width:5%'><i class='bi bi-arrow-clockwise'></i></a></div></div>");
                                            //Descriptions
                                            $("#contenedor-text-areas").append("<div id ='' class='form-group idioma idioma"+value2['id']+" idioma-noseleccionado'><label>Descripción "+value2['acronym']+"</label><textarea class='campo form-control textarea-core' name='traducciones["+value2['id']+"][description]' id='idioma"+value2['id']+"-description'></textarea></div>");
                                            $("#contenedor-text-areas").append("<div id ='' class='form-group idioma idioma"+value2['id']+" idioma-noseleccionado'><label>Descripción corta "+value2['acronym']+"</label><textarea class='campo form-control textarea-core' name='traducciones["+value2['id']+"][short_description]' id='idioma"+value2['id']+"-short_description'></textarea></div>");
                                        }
                                    });
                                }
                            });
                        },
                        complete: function(){
                            $(".modal-header").append("<button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>");
                            $.each(value,function(index2,value2){
                                $("#idioma"+value2['idioma_id']+"-"+value2['tipo']).val(value2['valor']);
                            });
                            tinymce.init({
                                selector: 'textarea',
                                menubar: '',
                                height: 150,
                            });
                        }
                    });
                }else{
                    $("#"+index.toLowerCase()).val(value);
                }
            });
        },
        complete: function(){
            changeSet();
        }

    }); 
});
$(document).on('click','.abreProductotrashModal', function(){
    
    var id = this.id.substr(9,25);
    var optionsTasas = "";
    $("#_method").val("GET");
    $("#formProductotrash").attr('action',"productostrash/recovery/"+id);
    $("#sku").attr('readonly',true);    
    $(".campo").val("");
    
    $.ajax({
        url: "productostrash/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#fichaProductotrashLabel").html("Recuperación de producto "+data['name']);
            $.each(data,function(index,value){
                $("#"+index.toLowerCase()).val(value);
            });
        }
    }); 
});
$(document).on('click','.nuevoProductoModal', function(){
    //HEADER
    $(".modal-header").empty();
    $(".comprobacion").remove();
    $(".modal-header").append("<h5 class='modal-title me-5' id='fichaProductoLabel'>Creación de nuevo producto</h5>");
    //FORM
    $("#_method").val("POST");
    $("#formProducto").attr('action',"productos");
    $("#btnDeleteProducto").css("display","none");
    $("#sku").attr('readonly',false);
    $("#tasa_id").empty();
    $("#contenedor-videos").empty();
    $("#carouselImagenesProductos .carousel-inner").empty();
    $("#carouselImagenesProductos .carousel-inner").append("<div class='carousel-item active'><img src='storage/product_images/no_img_avaliable.jpg' class='d-block' width='300px'></div>");
    //Names
    $("#contenedor-names").empty();
    $("#contenedor-names").append("<div class='form-group idioma idioma1 idioma-seleccionado'><label>Nombre*</label><input type='text' required class='campo form-control' name='name' id='name' style='text-align:center'></div>");
    //Url keys
    $("#contenedor-url-keys").empty();
    $("#contenedor-url-keys").append("<div class='idioma idioma1 idioma-seleccionado'><div class='form-group d-flex'><div style = 'width:95%'><label>URL Key*</label><input type='text' required class='campo form-control' name='url_key' id='url_key' style='text-align:center'></div><a href='#' class='btn mt-3 changeURL' style='width:5%'><i class='bi bi-arrow-clockwise'></i></a></div></div>");
    //Descriptions
    tinymce.remove("textarea");
    $("#contenedor-text-areas").empty();
    $("#contenedor-text-areas").append("<div id ='contenedor-description' class='form-group idioma idioma1 idioma-seleccionado'><label>Descripción</label><textarea class='campo form-control textarea-core' name='description' id='description'></textarea></div>");
    $("#contenedor-text-areas").append("<div id ='contenedor-shortdescription' class='form-group idioma idioma1 idioma-seleccionado'><label>Descripción corta</label><textarea class='campo form-control textarea-core' name='short_description' id='short_description'></textarea></div>");
    //Traducciones
    $.ajax({
        url: "tiendas/aux",
        type: "GET",
        dataType: "json",
        success: function(data2){
            $.each(data2,function(index,value){
                if(value['idiomas']){
                    $.each(value['idiomas'],function(index2,value2){
                        if(index2 == 0){
                            $(".modal-header").append("<a href='#' id='idioma-"+value2['id']+"' class='btn botonidioma botonidioma-seleccionado' title='' style='width:5%'>"+value2['acronym']+"</a>");
                        }else{
                            $(".modal-header").append("<a href='#' id='idioma-"+value2['id']+"' class='btn botonidioma' title='' style='width:5%'>"+value2['acronym']+"</a>");
                            //Names
                            $("#contenedor-names").append("<div class='form-group idioma idioma"+value2['id']+" idioma-noseleccionado'><label>Nombre "+value2['acronym']+"*</label><input type='text' class='campo form-control' name='traducciones["+value2['id']+"][name]' id='idioma"+value2['id']+"-name' style='text-align:center'></div>");
                            //Url keys
                            $("#contenedor-url-keys").append("<div class='idioma idioma"+value2['id']+" idioma-noseleccionado'><div class='form-group d-flex'><div style = 'width:95%'><label>URL Key "+value2['acronym']+"*</label><input type='text' class='campo form-control' name='traducciones["+value2['id']+"][url_key]' id='idioma"+value2['id']+"-url_key' style='text-align:center'></div><a href='#' class='btn mt-3 changeURL' style='width:5%'><i class='bi bi-arrow-clockwise'></i></a></div></div>");
                            //Descriptions
                            $("#contenedor-text-areas").append("<div id ='' class='form-group idioma idioma"+value2['id']+" idioma-noseleccionado'><label>Descripción "+value2['acronym']+"</label><textarea class='campo form-control textarea-core' name='traducciones["+value2['id']+"][description]' id='idioma"+value2['id']+"-description'></textarea></div>");
                            $("#contenedor-text-areas").append("<div id ='' class='form-group idioma idioma"+value2['id']+" idioma-noseleccionado'><label>Descripción corta "+value2['acronym']+"</label><textarea class='campo form-control textarea-core' name='traducciones["+value2['id']+"][short_description]' id='idioma"+value2['id']+"-short_description'></textarea></div>");
                        }
                    });
                }
            });
        },
        complete: function(){
            $(".modal-header").append("<button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>");
            $.each(value,function(index2,value2){
                console.log("#idioma"+value2['idioma_id']+"-"+value2['tipo']);
                console.log(value2['valor']);
                $("#idioma"+value2['idioma_id']+"-"+value2['tipo']).val(value2['valor']);
            });
        }
    });
    //Tasas
    var optionsTasas = "";
    $.ajax({
        url: "tasas/aux",
        type: "GET",
        dataType: "json",
        success: function(data){
            $.each(data,function(index,value){
                optionsTasas += "<option value='"+value['id']+"'>"+value['name']+"</option>";
            });
            $("#tasa_id").append(optionsTasas);
        }
    });
    configurablesInicio();
    //Atributos
    $(".atributos").empty();
    $(".atributos").append("<div class='w-100'><h2>Atributos</h2></div><hr class='w-100 mt-0'><div class='d-flex justify-content-center w-100 mb-3 producto-mobile-set'><label style='width:11%;padding-top:0.5rem'>Set de atributos:</label><select class='form-control' style='width:15%' name='setattribute_id' id='setattribute_id'></select></div>");
    $(".atributos").append("<div id='atributos-list' class='d-flex flex-row justify-content-left w-100 flex-wrap'></div>");
    $.ajax({
        url: "atributos/aux",
        type: "GET",
        dataType: "json",
        success: function(data){
            //Atributos
            $.each(data,function(index,value){
                if(value['type'] == "multiselect"){
                    var multiselect = "<div class='form-group atributo-contenedor' id='atributo-"+value['code']+"'><label>"+value['name']+"</label><select name='atributo["+value['id']+"][]' multiple class='form-control' id='multiselect-atributo-"+value['id']+"'></select></div>";
                    $("#atributos-list").append(multiselect);
                    var choicesMulti = Array();
                    $.each(value['valoresatributo'],function(index2,value2){
                        "<option "+value2['selected']+" value='"+value2['id']+"'>"+value2['name']+"</option>";
                        if(value2['selected'] == "selected"){
                            var selected = true;
                        }else{
                            var selected = false;
                        }
                        choicesMulti.push({
                            value:value2['id'],
                            label:value2['name'],
                            selected:selected,
                        });
                    });
                    var selectAtributo = new Choices("#multiselect-atributo-"+value['id'], {
                        choices: choicesMulti,
                        removeItemButton: true,
                        maxItemCount:-1,
                        searchResultLimit:-1,
                        renderChoiceLimit:-1,
                        searchChoices:true,
                        searchFields:"label",
                        noResultsText: 'Sin resultados',
                        noChoicesText:'Sin resultados',
                        placeholderValue:'Selecciona...',
                        itemSelectText: "",
                        shouldSort:true,
                    }); 
                }else if(value['type'] == "select"){
                    var select = "<div class='form-group atributo-contenedor' id='atributo-"+value['code']+"'><label>"+value['name']+"</label><select name='atributo["+value['id']+"]' class='form-control'>";
                    select += "<option value='0'>Nada seleccionado</option>";
                    $.each(value['valoresatributo'],function(index2,value2){
                        select += "<option "+value2['selected']+" value='"+value2['id']+"'>"+value2['name']+"</option>";
                    });
                    select += "</select></div>";
                    $("#atributos-list").append(select);                             
                }else if(value['type'] == "text"){
                    $("#atributos-list").append("<div class='form-group atributo-contenedor' id='atributo-"+value['code']+"'><label>"+value['name']+"</label><input name='atributo_varchar["+value['id']+"]' type='text' class='form-control' style='text-align:center' value=''></div>");
                }else if(value['type'] == "boolean"){
                    var select = "<div class='form-group atributo-contenedor' id='atributo-"+value['code']+"'><label>"+value['name']+"</label><select name='atributo_boolean["+value['id']+"]' class='form-control'>";
                    select += "<option selected value=''>Nada seleccionado</option><option value='0'>NO</option><option value='1'>SI</option>";
                    select += "</select></div>";
                    $("#atributos-list").append(select);
                }else if(value['type'] == "textarea"){
                    $("#contenedor-text-areas").append("<div id ='contenedor-"+value['code']+"' class='form-group atributo-text-area-contenedor'><label>"+value['name']+"</label><textarea class='campo form-control' name='atributo_textarea["+value['id']+"]'></textarea></div>");
                }
            });
            tinymce.init({
                selector: 'textarea',
                menubar: '',
                height: 150,
            });
            //Atributos clasificadores
            var choicesMulti = Array();
            $.each(data,function(index,value){
                if(value['type'] == "select"){
                    var selected = false;
                    choicesMulti.push({
                        value:value['id'],
                        label:value['name'],
                        selected:selected,
                    });
                }
            });
            var selectAtributosConfigurable = new Choices("#atributos_configurable", {
                choices: choicesMulti,
                removeItemButton: true,
                maxItemCount:-1,
                searchResultLimit:-1,
                renderChoiceLimit:-1,
                searchFields:"label",
                noResultsText: 'Sin resultados',
                noChoicesText:'Sin resultados',
                placeholderValue:'Selecciona...',
                itemSelectText: "",
            }); 
        }
    });
    //Categorias
    $.ajax({
        url: "categorias/aux",
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#categorias-container").empty();
            $("#categorias-container").append("<label>Categorias</label><select class='campo form-control' multiple   name='categorias[]' id='categorias' style='text-align:center'></select>");
            var choicesMulti = Array();
            $.each(data,function(index,value){
                var selected = false;
                choicesMulti.push({
                    value:value['id'],
                    label:value['name'],
                });
            });
            var selectCategorias = new Choices('#categorias', {
                choices: choicesMulti,
                removeItemButton: true,
                maxItemCount:-1,
                searchResultLimit:-1,
                renderChoiceLimit:-1,
                searchEnabled:true,
                noResultsText: 'Sin resultados',
                noChoicesText:'Sin resultados',
                placeholderValue:'Selecciona...',
                itemSelectText: "",
                shouldSort:true,
                fuseOptions: {
                    includeScore:true,
                    includeMatches:true,
                    findAllMatches:true,
                    keys:['label'],
                    threshold:1.0,
                }
            });    
        }
    });
    //Sets atributos
    $.ajax({
        url: "setatributos/aux",
        type: "GET",
        dataType: "json",
        success: function(data){
            $.each(data,function(index,value){
                if(value['id'] == 1){
                    console.log(value);
                    $("#setattribute_id").append("<option selected value='"+value['id']+"'>"+value['name']+"</option>");
                }else{
                    $("#setattribute_id").append("<option value='"+value['id']+"'>"+value['name']+"</option>");
                }
            });
            changeSet();
        }
    });
    tinymce.init({
        selector: 'textarea',
        menubar: '',
        height: 150,
    });
    bundlesInicio();
    changeType('simple');
    $(".campo").val("");
    $("#type").val('simple').change();
    $('#type option[value="bundle"]').attr('disabled',false);
    $('#type option[value="simple"]').attr('disabled',false);

});
$(document).on('click','.borraImagen',function(){
    if(confirm('¿Estás seguro de borrar la imagen?') == true){
        var id = this.id.substr(7,20);
        $.ajax({
            url: "productos/deleteImage/"+id,
            type: "GET",
            dataType: "json",
            success: function(data){
                $("#image-"+id).parent().remove();
                if($("#carouselImagenesProductos .carousel-inner .carousel-item").length == 0){
                    $("#carouselImagenesProductos .carousel-inner").append("<div class='carousel-item active'><img src='storage/product_images/no_img_avaliable.jpg' class='d-block' width='300px'></div>");
                    $("#thumbnail-tabla-"+data['producto_id']).attr('src','storage/product_images/no_img_avaliable.jpg');
                }else{
                    $("#carouselImagenesProductos .carousel-inner .carousel-item:first").addClass('active');
                }
            }
        }); 
    }
});
$(document).on("change",".input-video",function(){
    var url = $(this).val();
    var id  = this.id.substr(6,20);
    $(this).parent().children(".visor").attr('href',url);
});
$(document).on("click",".addinputvideo",function(){
    $("#contenedor-videos").append("<div class='d-flex mb-3'><a class='btn deleteinputvideo' href='#'><i class='bi bi-x-circle'></i></a><input class='form-control input-video' value='' name='videos[]'><a class='btn visor' target='_blank' ><i class='bi bi-eye'></i></a></div>");
});
$(document).on("click",".deleteinputvideo",function(){
    $(this).parent().remove();
});
$(document).on("change","#setattribute_id",function(){
    changeSet();
})
$(document).on("change","#tipo_configurable",function(){
    var id = $("#producto_id").val();
    $.ajax({
        url: "productos/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            console.log(data);
            if($("#tipo_configurable").val() == ""){
                changeConfigurable();
            }else if($("#tipo_configurable").val() == "parent"){
                changeConfigurable(data['childs']);
            }else if($("#tipo_configurable").val() == "child"){
                changeConfigurable(data['parent']);
            }
        }
    });
});
$(document).on("click",".changeURL",function(){
    var id = this.id.substr(10,10);
    changeURL(id);
});
$(document).on("change",".sku-child",function(){
    var name          = $(this).parent().parent().children('.container-name-child').children('.name-child');
    var price         = $(this).parent().parent().children('.container-price-child').children('.price-child');
    var special_price = $(this).parent().parent().children('.container-special-price-child').children('.special-price-child');
    var stock         = $(this).parent().parent().children('.container-stock-child').children('.stock-child');
    var sku_input     = $(this);
    var consulta      = true;
    //Comprueba si es el mismo producto
    if(sku_input.val() == $("#sku").val()){
        alert("El SKU que intentas agregar es el propio producto padre.");
        sku_input.val("");
        name.val("");
        price.val("");
        special_price.val("");
        stock.val("");
        consulta = false;
    }
    //Comprueba que no este en el listado de hijos ya agregado
    var skus = new Array();
    $.each($(".sku-child"),function(index,value){
        skus.push($(value).val());
    });
    skus.pop();
    $.each(skus,function(index,value){
        if(value == sku_input.val()){
            alert("El SKU que intentas agregar ya está como hijo de este padre.");
            sku_input.val("");
            name.val("");
            price.val("");
            special_price.val("");
            stock.val("");
            consulta = false;
        }
    });
    //Comprueba que el producto tenga el atributo clasificador asignado

    //Ejecuta la consulta
    if(consulta == true){
        $.ajax({
            url: "configurables/"+$(this).val(),
            type: "GET",
            dataType: "json",
            success: function(data){
                var tieneAtributo = [];
                $.each($("#atributos_configurable").val(),function(index,value){
                    tieneAtributo[index] = false;
                    $.each(data['assigned_atributos'],function(index2,value2){
                        if(value2['atributo_id'] == value){
                            tieneAtributo[index] = true;
                        }
                    });    
                });
                if($.inArray(false,tieneAtributo) == -1){
                    name.val(data['name']);
                    price.val(data['price']);
                    special_price.val(data['special_price']);
                    stock.val(data['stock']);
                }else{
                    alert("El SKU que intentas agregar no tiene asignado ningún/algún atributo clasificador.");   
                    sku_input.val("");
                    name.val("");
                    price.val("");
                    special_price.val("");
                    stock.val("");                 
                }
            },
            error: function(){
                alert("El SKU que intentas agregar no corresponde a ningún producto del catálogo.");
                sku_input.val("");
                name.val("");
                price.val("");
                special_price.val("");
                stock.val("");
            }
        });
    }
});
$(document).on("click",".addinputchild",function(){
    var html = "<div class='d-flex justify-content-between w-100'>";    
        //html += "<div style='width:4%'></div>";
        html += "<div class='m-auto'><a class='btn deleteinputchild' href='#'><i class='bi bi-x-circle'></i></a></div>";
        html += "<div id='' class='form-group container-sku-child container-sku-child'><label>SKU:</label><input id='' class='form-control sku-child' name='childs[]' value=''></div>";
        html += "<div id='' class='form-group container-name-child'><label>Nombre:</label><input readonly id='' class='form-control name-child' name='' value=''></div>";
        html += "<div id='' class='form-group container-price-child'><label>Precio:</label><input readonly id='' class='form-control price-child' name='' value=''></div>";
        html += "<div id='' class='form-group container-special-price-child'><label>Oferta:</label><input readonly id='' class='form-control special-price-child' name='' value=''></div>";
        html += "<div id='' class='form-group container-stock-child' class=''><label>Stock:</label><input readonly id='' class='form-control stock-child' name='' value=''></div>";
        html += "</div>";
    $("#childs-list").append(html);
});
$(document).on("click",".deleteinputchild",function(){
    $(this).parent().parent().remove();
});
$(document).on("click",".compara-tiendas",function(){
    $(".comprobacion").remove();
    $(".tooltipp").remove();
    $.ajax({
        url: "consultas/producto/"+this.id.substr(15,20),
        type: "GET",
        dataType: "json",
        success: function(data){
            console.log(data);
            $.each(data,function(index,value){
                if($("#"+index).length  != 0){
                    if($("#"+index).val() == value){
                        $("#"+index).parent().children('label').append("<i style='color:green' class='bi bi-check-circle comprobacion ms-1'></i>");
                    }else{
                        $("#"+index).parent().children('label').append("<i style='color:red' class='bi bi-x-circle tooltipp comprobacion ms-1'><span class='tooltip-box'>"+value+"</span></i>");
                        var icono    = $("#"+index).parent().children('label').children('i');
                        var tooltip = $("#"+index).parent().children('label').children('i').children('span');
                        var anchoTooltip = tooltip.width();
                        var altoTooltip = tooltip.height();
                        var left = 0 - (anchoTooltip / 2) - 2;
                        var top  = 0 - altoTooltip - 17;
                        tooltip.css('left',left);
                        tooltip.css('top',top);
                    }
                }
                if(index == 'custom_attributes'){
                    $.each(value,function(index2,value2){
                        if(value2['attribute_code'] == 'special_price'){
                            value2['value'] = value2['value'].slice(0,-4);
                        }
                        if($("#"+value2['attribute_code']).length != 0){
                            if(value2['value'] == $("#"+value2['attribute_code']).val()){
                                $("#"+value2['attribute_code']).parent().children('label').append("<i style='color:green' class='bi bi-check-circle comprobacion ms-1'></i>");
                            }else{
                                $("#"+value2['attribute_code']).parent().children('label').append("<i style='color:red' class='bi bi-x-circle comprobacion tooltipp ms-1'><span class='tooltip-box'>"+value2['value']+"</span></i>");
                                var icono    = $("#"+value2['attribute_code']).parent().children('label').children('i');
                                var tooltip = $("#"+value2['attribute_code']).parent().children('label').children('i').children('span');
                                var anchoTooltip = tooltip.width();
                                var altoTooltip = tooltip.height();
                                var left = 0 - (anchoTooltip / 2) - 2;
                                var top  = 0 - altoTooltip - 17;
                                tooltip.css('left',left);
                                tooltip.css('top',top);
                            }
                        }
                    });
                }
            });
        },
        error: function(){
            alert("No hay conexión con la tienda solicitada.");
        }
    });
});
$(document).on("click",".addinputskubundle",function(){
    $(this).parent().parent().parent().children(".collapse").append(`
        <div class='d-flex w-100 justify-content-between form-group'>
            <div class='ms-2 mt-4' style='width:3%'>
                <a class='btn deleteinputskubundle' href='#'>
                    <i class='bi bi-x-circle'></i>
                </a>
            </div>
            <div class='container-sku-bundle' style='width:10%'>
                <label>SKU</label>
                <input class='form-control sku-bundle' name='skusbundle[`+$(this).parent().parent().children(".id-bundle").val()+`][]' type='text' value=''>
            </div>
            <div class='w-50 container-name-bundle'>
                <label>Nombre</label>
                <input class='form-control name-bundle' type='text' disabled value=''>
            </div>
            <div class='container-price-bundle' style='width:10%'>
                <label>Precio</label>
                <input class='form-control price-bundle' type='number' step='0.01' disabled value=''>
            </div>
            <div style='width:10%' class='container-special-price-bundle'>
                <label>Precio especial</label>
                <input class='form-control special-price-bundle' type='number' step='0.01' disabled value=''>
            </div>
            <div style='width:10%'  class='container-stock-bundle'>
                <label>Stock</label>
                <input class='form-control stock-bundle' type='number' step='1.00' disabled value=''>
            </div>
        </div>
    `);
});
$(document).on("click",".deleteinputskubundle",function(){
    $(this).parent().parent().remove();
});
$(document).on("change",".sku-bundle",function(){
    var name          = $(this).parent().parent().children('.container-name-bundle').children('.name-bundle');
    var price         = $(this).parent().parent().children('.container-price-bundle').children('.price-bundle');
    var special_price = $(this).parent().parent().children('.container-special-price-bundle').children('.special-price-bundle');
    var stock         = $(this).parent().parent().children('.container-stock-bundle').children('.stock-bundle');
    var sku_input     = $(this);
    var consulta      = true;
    //Comprueba si es el mismo producto
    if(sku_input.val() == $("#sku").val()){
        alert("El SKU que intentas agregar es el propio producto.");
        sku_input.val("");
        name.val("");
        price.val("");
        special_price.val("");
        stock.val("");
        consulta = false;
    }
    //Comprueba que no este en el listado de hijos ya agregado
    var skus = new Array();
    $.each($(".sku-bundle"),function(index,value){
        skus.push($(value).val());
    });
    skus.pop();
    $.each(skus,function(index,value){
        if(value == sku_input.val()){
            alert("El SKU que intentas agregar ya está agregado a esta configuración.");
            sku_input.val("");
            name.val("");
            price.val("");
            special_price.val("");
            stock.val("");
            consulta = false;
        }
    });
    //Ejecuta la consulta
    if(consulta == true){
        $.ajax({
            url: "bundles/"+$(this).val(),
            type: "GET",
            dataType: "json",
            success: function(data){
                name.val(data['name']);
                price.val(data['price']);
                special_price.val(data['special_price']);
                stock.val(data['stock']);
            },
            error: function(){
                alert("El SKU que intentas agregar no corresponde a ningún producto del catálogo.");
                sku_input.val("");
                name.val("");
                price.val("");
                special_price.val("");
                stock.val("");
            }
        });
    }
});
$(document).on("change","#type",function(){
    changeType($(this).val());
});
$(document).on("click",".botonidioma",function(){
    var id_boton = this.id;
    var id       = id_boton.substr(7,10);
    $(".idioma").removeClass("idioma-seleccionado");
    $(".idioma").addClass("idioma-noseleccionado");
    $(".idioma"+id).removeClass("idioma-noseleccionado");
    $(".idioma"+id).addClass("idioma-seleccionado");
    $(".botonidioma").removeClass("botonidioma-seleccionado");
    $("#"+id_boton).addClass("botonidioma-seleccionado");
});

$(document).on('click','.btn-delete-producto',function(){
    var id = this.id.substr(10,25);
    Swal.fire({
        title: "Estás seguro que deseas eliminar este producto?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Eliminar",
        denyButtonText: 'No eliminar'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            $("#delete-"+id).submit();
        } else if (result.isDenied) {
          Swal.fire("El producto no se eliminó", "", "info");
        }
    });
});

function changeConfigurable(data){
    if($("#tipo_configurable").val() == ""){
        $("#contenedor-atributos-configurables").addClass('d-none');
        $("#childs-list").empty();
    }else if($("#tipo_configurable").val() == "parent"){
        $("#contenedor-atributos-configurables").removeClass('d-none');
        $("#childs-list").empty();
        $("#childs-list").append("<div class='w-100 d-flex'><h4 class='ms-3'>Hijos</h4><a class='btn addinputchild' href='#'><i class='bi bi-plus-circle'></i></a></div>");
        $.each(data,function(index,value){
            var html = "<div class='d-flex justify-content-between w-100'>";
            html += "<div class='m-auto'><a class='btn deleteinputchild' href='#'><i class='bi bi-x-circle'></i></a></div>";
            html += "<div id='' class='form-group container-sku-child container-sku-child'><label>SKU:</label><input readonly id='' class='form-control sku-child' name='childs[]' value='"+value['sku']+"'></div>";
            html += "<div id='' class='form-group container-name-child'><label>Nombre:</label><input readonly id='' class='form-control name-child' name='' value='"+value['name']+"'></div>";
            html += "<div id='' class='form-group container-price-child'><label>Precio:</label><input readonly id='' class='form-control price-child' name='' value='"+value['price']+"'></div>";
            html += "<div id='' class='form-group container-special-price-child'><label>Oferta:</label><input readonly id='' class='form-control special-price-child' name='' value='"+value['special_price']+"'></div>";
            html += "<div id='' class='form-group container-stock-child' class=''><label>Stock:</label><input readonly id='' class='form-control stock-child' name='' value='"+value['stock']+"'></div>";
            html += "</div>";
            $("#childs-list").append(html);
        });
        var html = "<div class='d-flex justify-content-between w-100'>";
        //html += "<div style='width:4%'></div>";
        html += "<div class='m-auto'><a class='btn deleteinputchild' href='#'><i class='bi bi-x-circle'></i></a></div>";
        html += "<div id='' class='form-group container-sku-child container-sku-child'><label>SKU:</label><input id='' class='form-control sku-child' name='childs[]' value=''></div>";
        html += "<div id='' class='form-group container-name-child'><label>Nombre:</label><input readonly id='' class='form-control name-child' name='' value=''></div>";
        html += "<div id='' class='form-group container-price-child'><label>Precio:</label><input readonly id='' class='form-control price-child' name='' value=''></div>";
        html += "<div id='' class='form-group container-special-price-child'><label>Oferta:</label><input readonly id='' class='form-control special-price-child' name='' value=''></div>";
        html += "<div id='' class='form-group container-stock-child' class=''><label>Stock:</label><input readonly id='' class='form-control stock-child' name='' value=''></div>";
        html += "</div>";
        $("#childs-list").append(html);
    }else if($("#tipo_configurable").val() == "child"){
        $("#contenedor-atributos-configurables").addClass('d-none');
        $("#childs-list").empty();
        $("#childs-list").append("<div class='w-100 d-flex'><h4 class='ms-3'>Padre</h4></div>");
        $.each(data,function(index,value){
            var html = "<div class='d-flex justify-content-between w-100'>";
            html += "<div id='' class='form-group container-sku-parent'><label>SKU:</label><input disabled id='' class='form-control sku-parent' name='' value='"+value['sku']+"'></div>";
            html += "<div id='' class='form-group container-name-parent'><label>Nombre:</label><input disabled id='' class='form-control name-parent' name='' value='"+value['name']+"'></div>";
            html += "<div id='' class='form-group container-price-parent'><label>Precio:</label><input disabled id='' class='form-control price-parent' name='' value='"+value['price']+"'></div>";
            html += "<div id='' class='form-group container-special-price-parent'><label>Oferta:</label><input disabled id='' class='form-control special-price-parent' name='' value='"+value['special_price']+"'></div>";
            html += "<div id='' class='form-group container-stock-parent'><label>Stock:</label><input disabled id='' class='form-control stock-parent' name='' value='"+value['stock']+"'></div>";
            html += "</div>";
            $("#childs-list").append(html);
        });
    }
}
function changeSet(){
    var idSet = $("#setattribute_id").val();
    $.ajax({
        url: "setatributos/"+idSet,
        type: "GET",
        dataType: "json",
        success: function(data){
            $(".atributo-contenedor").hide();
            $.each($(".atributo-contenedor"),function(index,value){
                $.each(data['atributos'],function(index2,value2){
                    if(value.id == "atributo-"+value2['code']){
                        $("#"+value.id).show();
                    }
                });
            });
            $(".atributo-text-area-contenedor").hide();
            $.each($(".atributo-text-area-contenedor"),function(index,value){
                $.each(data['atributos'],function(index2,value2){
                    if(value.id == "contenedor-"+value2['code']){
                        $("#"+value.id).show();
                    }
                });
            });
        }
    });
}
function changeURL(id){
    /*
    $.ajax({
        url: "productos/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            console.log(data['generada_url_key']);
            $("#url_key").val(data['generada_url_key']);          
        }
    });
    */
    var url_key = "";
    var name = $("#name").val().split(' ');
    $.each(name,function(index,value){
        if(value){
            if(url_key == ""){
                url_key += value.replace("á","a").replace("à","a").replace("é","e").replace("è","e").replace("í","i").replace("ì","i").replace("ó","o").replace("ò","o").replace("ú","u").replace("ù","u").replace("'","").replace('"',"").replace("/","").replace(",","").replace("`","").replace("´","");
            }else{
                url_key += "-"+value.replace("á","a").replace("à","a").replace("é","e").replace("è","e").replace("í","i").replace("ì","i").replace("ó","o").replace("ò","o").replace("ú","u").replace("ù","u").replace("'","").replace('"',"").replace("/","").replace(",","").replace("`","").replace("´","");
            }
        }
    });
    if($("#sku").val().length != 0){
        url_key += "-"+$("#sku").val();
    }
    $("#url_key").val(url_key.toLowerCase());
};
function configurablesInicio()
{
    //Configurables inicio
    $(".configurables").empty();
    var htmlConf = "<div class='w-100'><h2 class='m-0'>Configurables</h2></div>";
    htmlConf    += "<hr class='w-100 mt-0'>";
    htmlConf    += "<div class='d-flex w-100'>";
    htmlConf    += "<div class='d-flex justify-content-center w-100 mb-3 producto-mobile-tipoconfigurable'>";
    htmlConf    += "<label style='width:11%;padding-top:0.5rem'>Tipo:</label>";
    htmlConf    += "<select class='form-control' style='width:15%' name='tipo_configurable' id='tipo_configurable'>";
    htmlConf    += "<option value=''>Sin tipo</option><option value='child'>Hijo</option><option value='parent'>Padre</option>";
    htmlConf    += "</select></div>";
    htmlConf    += "<div id='contenedor-atributos-configurables' class='d-flex justify-content-center w-100 mb-3 producto-mobile-atributoconfigurable'>";
    htmlConf    += "<label style='width:11%;padding-top:0.5rem'>Atributos:</label>";
    htmlConf    += "<select class='form-control' style='width:15%' name='atributos_configurable[]' multiple id='atributos_configurable'>";
    htmlConf    += "</select></div>";
    htmlConf    += "</div>";
    htmlConf    += "<div id='childs-list' class='d-flex flex-row justify-content-left w-100 flex-wrap'></div>";
    $(".configurables").append(htmlConf);
    
    changeConfigurable();
}
function bundlesInicio()
{
    //Bundles inicio
    $(".bundles").empty();
    var htmlBund = "<div class='w-100'><h2 class='m-0'>Bundles</h2></div>";
    htmlBund    += "<hr class='w-100 mt-0'>";
    htmlBund    += "<div class='w-100 options'></div>";
    $(".bundles").append(htmlBund);
}
function changeType(type)
{
    if(type == 'simple'){
        $(".configurables").addClass('d-none');
        $(".configurables").removeClass('d-flex');
        $(".bundles").addClass('d-none');
        $(".bundles").removeClass('d-flex');
    }else if(type == 'configurable'){
        $(".configurables").addClass('d-flex');
        $(".configurables").removeClass('d-none');
        $(".bundles").addClass('d-none');
        $(".bundles").removeClass('d-flex');
    }else if(type == 'bundle'){
        $(".configurables").addClass('d-none');
        $(".configurables").removeClass('d-flex');
        $(".bundles").addClass('d-flex');
        $(".bundles").removeClass('d-none');
    }
}



//Tasas
$(document).on('click','.abreTasaModal', function(){
        
    var id = this.id.substr(5,25);
    $("#_method").val("PUT");
    $("#formTasa").attr('action',"tasas/"+id);
    $("#btnDeleteTasa").css("display","block");
    $("#btnDeleteTasa").attr('onclick',"document.getElementById('delete-"+id+"').submit()");
    
    
    $(".campo").val("");
    $.ajax({
        url: "tasas/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            console.log(data);
            $("#fichaTasaLabel").html("Edición de tasa "+data['name']);
            $.each(data,function(index,value){
                $("#"+index.toLowerCase()).val(value);
            });
        }
    }); 
});
$(document).on('click','.nuevaTasaModal', function(){
    $("#_method").val("POST");
    $("#formTasa").attr('action',"tasas");
    $("#fichaTasaLabel").html("Creación de nueva tasa");
    $("#btnDeleteTasa").css("display","none");
    
    $(".campo").val("");

});

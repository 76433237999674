//Tasas
$(document).on('click','.abreTransporteModal', function(){
        
    var id = this.id.substr(11,25);
    $("#_method").val("PUT");
    $("#formTransporte").attr('action',"transportes/"+id);
    $("#btnDeleteTransporte").css("display","block");
    $("#btnDeleteTransporte").attr('onclick',"document.getElementById('delete-"+id+"').submit()");
    
    
    $(".campo").val("");
    $.ajax({
        url: "transportes/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#fichaTransporteLabel").html("Edición de transporte "+data['name']);
            $.each(data,function(index,value){
                $("#"+index.toLowerCase()).val(value);
            });
        }
    }); 
});
$(document).on('click','.nuevoTransporteModal', function(){
    $("#_method").val("POST");
    $("#formTransporte").attr('action',"transportes");
    $("#fichaTransporteLabel").html("Creación de nuevo transporte");
    $("#btnDeleteTransporte").css("display","none");
    
    $(".campo").val("");

});

//Parametros
$(document).on('click','.abreParametroModal', function(){
        
    var id = this.id.substr(5,25);
    $("#_method").val("PUT");
    $("#formParametro").attr('action',"parametros/"+id);    
    
    $(".campo").val("");
    $.ajax({
        url: "parametros/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            console.log(data);
            $("#fichaParametroLabel").html("Edición de parametro "+data['name']);
            $.each(data,function(index,value){
                $("#"+index.toLowerCase()).val(value);
            });
        }
    }); 
});

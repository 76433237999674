//Categorias
$(document).on('click','.nuevaCategoriaModal', function(){
    $(".modal-body #_method").val("POST");
    $(".modal-body #formCategoria").attr('action',"categorias");
    $("#fichaCategoriaLabel").html("Creación de nueva categoría");
    $(".campo").val("");

    $.ajax({
        url: "categorias/levels",
        type: "GET",
        dataType: "json",
        success: function(data){
            console.log(data);
            $.each(data,function(index,value){
                $(".modal-body #level").append("<option value='"+value+"'>Nivel "+value+"</option>");
            });
            
        }
    });

});
$(document).on("change",".modal-body #level",function(){
    $(".modal-body #parent_id").empty();
    $.ajax({
        url: "categorias/parents/"+($(this).val()-1),
        type: "GET",
        dataType: "json",
        success: function(data){
            $.each(data,function(index,value){
                $(".modal-body #parent_id").append("<option value='"+value['id']+"'>"+value['name']+"</option>");
            }); 
        }
    });
});
$(document).on("change","#details-categories #level",function(){
    $("#details-categories #parent_id").empty();
    $.ajax({
        url: "categorias/parents/"+($(this).val()-1),
        type: "GET",
        dataType: "json",
        success: function(data){
            $.each(data,function(index,value){
                $("#details-categories #parent_id").append("<option value='"+value['id']+"'>"+value['name']+"</option>");
            }); 
        }
    });
});
//Arbol categorias
$(document).on('click',".item-level1 .bi",function(){
    $(this).parent().parent().find(".item-level2").toggle();
});
$(document).on('click',".item-level2 .bi",function(){
    $(this).parent().parent().find(".item-level3").toggle();
});
$(document).on('click',".item-level3 .bi",function(){
    $(this).parent().parent().find(".item-level4").toggle();
});
$(document).on('click',".item-level4 .bi",function(){
    $(this).parent().parent().find(".item-level5").toggle();
});
function rellenaDetailsCategories(id){
    $.ajax({
        url: "categorias/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $(".campo").val("");
            $("#details-categories #formCategoria").attr('action',"categorias/"+id);
            $("#details-categories #formDeleteCategoria").attr('action',"categorias/"+id);
            $("#details-categories #name").val(data['name']);
            $("#details-categories #level").empty();
            $.ajax({
                url: "categorias/levels",
                type: "GET",
                dataType: "json",
                success: function(data2){
                    $.each(data2,function(index2,value2){
                        if(data['level'] == value2){
                            $("#level").append("<option selected value='"+value2+"'>Nivel "+value2+"</option>");
                        }else{
                            $("#level").append("<option value='"+value2+"'>Nivel "+value2+"</option>");
                        }
                    });
                    
                }
            }); 
            $("#details-categories #parent_id").empty();
            $.ajax({
                url: "categorias/parents/"+(data['level']-1),
                type: "GET",
                dataType: "json",
                success: function(data3){
                    $.each(data3,function(index3,value3){
                        if(data['parent_id'] == value3['id']){
                            $("#parent_id").append("<option selected value='"+value3['id']+"'>"+value3['name']+"</option>");
                        }else{
                            $("#parent_id").append("<option value='"+value3['id']+"'>"+value3['name']+"</option>");
                        }
                    }); 
                }
            });
            $("#details-categories #meta_title").val(data['meta_title']);
            $("#details-categories #meta_description").val(data['meta_description']);
            if(data['status'] == 1){
                $("#status").attr("checked",true);
            }else{
                $("#status").attr("checked",false);
            }
            //Traducciones
            $.each(data["traducciones"],function(index,value){
                $("#idioma"+value['idioma_id']+"-"+value['tipo']).val(value['valor']);
            });
            //Activamos botones
            $("#submitFormCategoria").attr('disabled',false);
            $("#btnProductosCategoria").attr("href","productos?campofiltro=categoria_id&valorfiltro="+id);
            $("#btnProductosCategoria").css("pointer-events","auto");
            $("#btnDeleteCategoria").css("pointer-events","auto");
        }
    });
}
$(document).on('click',".item-level1 p",function(){
    var id = this.id.substr(4,100);
    rellenaDetailsCategories(id);
});
$(document).on('click',".item-level2 p",function(){
    var id = this.id.substr(4,100);
    rellenaDetailsCategories(id);
});
$(document).on('click',".item-level3 p",function(){
    var id = this.id.substr(4,100);
    rellenaDetailsCategories(id);
});
$(document).on('click',".item-level4 p",function(){
    var id = this.id.substr(4,100);
    rellenaDetailsCategories(id);
});

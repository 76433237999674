//Pedidos
$(document).on('click','.abrePedidoModal', function(){
    
    var id = this.id.substr(7,25);
    //Formularios
    //Vaciado de campos
    $(".campo").val("");
   //Consulta producto
    $.ajax({
        url: "pedidos/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            //HEADER
            $(".modal-header").empty();
            $(".comprobacion").remove();
            $(".modal-header").append("<h5 class='modal-title' id='fichaPedidoLabel'>Pedido #"+data['increment_id']+"</h5>");
            $(".cancelarPedido").attr('id',"cancelarPedido-"+data['id']);
            if(data['status'] != 'processing' && data['status'] != 'complete' && data['status'] != 'canceled'){
                $(".cancelarPedido").prop('disabled',true);
            }else{
                $(".cancelarPedido").prop('disabled',true);
            }
            $.each(data,function(index,value){
                $("#"+index.toLowerCase()).val(value);
                if(index == "lineas"){
                    $(".lista-productos").empty();
                    $(".lista-productos").append(`
                        <div class='w-100 d-flex flex-row justify-content-between border-bottom' style='position:sticky; top:0; background-color:#fff'>
                            <div style='width:15%' class='m-auto text-center m-auto fw-bolder'>SKU</div>
                            <div style='width:35%' class='m-auto text-center m-auto fw-bolder'>Nombre</div>
                            <div style='width:10%' class='m-auto text-center m-auto fw-bolder'>Precio</div>
                            <div style='width:10%' class='m-auto text-center m-auto fw-bolder'>Oferta</div>
                            <div style='width:10%' class='m-auto text-center m-auto fw-bolder'>Stock</div>
                            <div style='width:20%' class='m-auto text-center m-auto fw-bolder'>Cantidad pedida</div>
                        </div>
                    `);

                    $.each(value,function(index2,value2){
                        var html = `
                                    <div id="linea-`+value2['id']+`" class='w-100 d-flex flex-row justify-content-between border-top'>
                        `;
                        if(value2['producto'] != null){
                            html += `
                                        <div style='width:15%' class='m-auto text-center m-auto'>`+value2['sku']+`</div>
                                        <div style='width:35%' class='m-auto text-center m-auto'>`+value2['producto']['name']+`</div>
                                        <div style='width:10%' class='m-auto text-center m-auto'>`+value2['producto']['price']+`</div>
                                        <div style='width:10%' class='m-auto text-center m-auto'>`+value2['producto']['special_price']+`</div>
                                        <div style='width:10%' class='m-auto text-center m-auto'>`+value2['producto']['stock']+`</div>
                                        <div style='width:20%' class='m-auto text-center m-auto'>`+value2['qty_ordered']+`</div>
                            `;
                        }else{
                            html += `
                                        <div style='width:15%' class='m-auto text-center m-auto'>`+value2['sku']+`</div>
                                        <div style='width:35%' class='m-auto text-center m-auto'>Not found 404</div>
                                        <div style='width:10%' class='m-auto text-center m-auto'>Not found 404</div>
                                        <div style='width:10%' class='m-auto text-center m-auto'>Not found 404</div>
                                        <div style='width:10%' class='m-auto text-center m-auto'>Not found 404</div>
                                        <div style='width:20%' class='m-auto text-center m-auto'>`+value2['qty_ordered']+`</div>
                            `;
                        }
                        html += `
                                    </div>
                        `;
                        $(".lista-productos").append(html);
                    });
                }else if(index == "created_at_origin"){
                    $("#fecha").val(value.substr(0,10));
                    $("#hora").val(value.substr(11,8));
                }
            });
        },
        complete: function(){
        }

    }); 
});
$(document).on('click','.cancelarPedido',function(){
    if(confirm('Estás seguro que deseas cancelar el pedido en la tienda Online? Esta acción es irreversible') == true) {
        var id = this.id.substr(15,20);
        $.ajax({
            url: "pedidos/cancelarpedido/"+id,
            type: "GET",
            dataType: "json",
            success: function(data){
                console.log(data);
                alert(data);
            }
        }); 
    }
});

